<script setup lang="ts">
import { ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';
const open = ref(false);
const close = () => {
    if (open.value) open.value = false;
}

function openItem() {
    // timeout to prevent click event from bubbling up
    if (open.value) return;
    setTimeout(() => {
        open.value = true;
    }, 0);
}

</script>
<template>
    <span class="text-sm text-gray-500 relative" @click="openItem"><font-awesome-icon
                           icon="fa fa-solid fa-info-circle" />
        <div v-show="open" v-click-away="close"
             class="absolute z-10 bottom-0 right-0 md:left-0 md:right-[unset] backdrop-blur opacity-90 mb-6 inline-block w-64 bg-white text-gray-800 shadow-md rounded-md p-2">
            <slot />
        </div>
    </span>
</template>