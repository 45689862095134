<script setup lang="ts">
import { ref, onBeforeMount } from 'vue';
import MemberLayout from "@/Layouts/MemberLayout.vue";
import Card from "../../Components/Card.vue";
import PrimaryButton from "../../Components/PrimaryButtonWhite.vue";
import { formatDate } from "../../composables/formatDate.js";
import useGradients from "../../composables/gradients.ts";
import { router } from '@inertiajs/vue3';


const { assessments, title } = defineProps<{ assessments: Array<object>, title: string }>()
const { linearGradient } = useGradients();
const date = s => formatDate(s);

// If only one assessment is available and not made yet, redirect to that assessment
const isOneToStart = ref(assessments.length == 1 && assessments[0].status.current_status !== 'completed');
onBeforeMount(() => {
    if (isOneToStart.value) {
        router.visit(route('assessments.show', assessments[0].slug));
    }

})

function isShort(slug) {
    return slug === 'opleidingsniveau';
}

</script>

<template>
    <MemberLayout v-if="!isOneToStart" :title="title">
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">{{ title }}</h2>
        </template>


        <div class="bg-white p-2 border rounded shadow-sm">
            <div class="max-w-7xl full flex gap-12 mx-auto py-10 sm:px-6 lg:px-8 shrink-0 grow-0 flex-wrap">
                <template v-for="({ status, name, updated_at, slug }, key) in assessments" :key="key">
                    <Card class="sm:w-3/4 lg:w-5/12 text-white"
                          :style="{ 'background-image': linearGradient('to bottom', slug) }">
                        <font-awesome-icon class="w-8 h-8" :class="{
                            'text-green': status.current_status === 'completed'
                            , 'text-gray-300': status.current_status !== 'completed'
                        }" icon="fa-solid fa-circle-check fa-xl"></font-awesome-icon>
                        <h2 class="text-md font-bold mb-1">{{ name }}</h2>
                        <p class="mt-1 text-xs">uitgenodigd op: {{ date(status.invited_at) }}</p>
                        <div class="mt-4">
                            <PrimaryButton v-if="status.current_status !== 'completed'" type="link"
                                           :href="route('assessments.show', slug)">
                                {{ isShort(slug) ? "Vul in" : "Start de test" }}
                            </PrimaryButton>
                            <Link v-else :href="route('assessments.show', slug)">
                            bekijk de resultaten

                            </Link>
                        </div>
                    </Card>
                </template>
            </div>
        </div>
    </MemberLayout>
</template>
