<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import SecondaryButton from '../../Components/SecondaryButton.vue';

const props = defineProps({
  canResetPassword: Boolean,
  status: String,
  email: String,
});

const form = useForm({
  email: props.email,
  password: '',
  remember: false,
});

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password'),
    });
};
</script>

<template>
  <Head title="Log in" />

  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div v-show="false">
        <InputLabel for="email" value="email" />
        <TextInput
                   id="email"
                   v-model="form.email"
                   type="email"
                   class="mt-1 block w-full"
                   required />
        <InputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <InputLabel for="password" value="wachtwoord" />
        <TextInput
                   id="password"
                   v-model="form.password"
                   type="password"
                   class="mt-1 block w-full"
                   required
                   autofocus
                   autocomplete="current-password" />
        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="block mt-4">
        <label class="flex items-center">
          <Checkbox v-model:checked="form.remember" name="remember" />
          <span class="ml-2 text-sm text-gray-600">Blijf ingelogd</span>
        </label>
      </div>

      <div class="flex items-center flex-col justify-end mt-4">
        <PrimaryButton
                       class="mt-4 w-full"
                       :class="{ 'opacity-25': form.processing }"
                       :disabled="form.processing">
          inloggen
        </PrimaryButton>
        <Link
              :href="route('login')"
              type="button"
              class="inline-flex items-center px-4 py-2 bg-white border border-gray-300 rounded-md font-semibold text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200 active:text-gray-800 active:bg-gray-50 disabled:opacity-25 transition mt-4 w-full"
              preserve-state>
        Terug
        </Link>
      </div>
    </form>
  </AuthenticationCard>
</template>
