<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
    currentPage: number,
    totalPages: number,
    currentSection: number,
    totalSections: number,
}>();

const progressPages = computed(() => {
    return Math.round((props.currentPage / props.totalPages) * 100);
})

const progressSections = computed(() => {
    return Math.round((props.currentSection / props.totalSections) * 100);
})

const progressStyle = computed(() => {
    if (props.currentPage > props.totalPages) {
        return {
            width: `${progressSections.value}%`,
        }
    } else {
        return {
            width: `${progressPages.value}%`,
        }
    }
})
</script>
<template>
    <div class="w-4/5 bg-gray-300 h-2 mx-auto rounded my-3">
        <div class="bg-blue-800 h-full rounded flex flex-row-reverse content-center transition duration-500 ease-in-out"
            :style="progressStyle">
            <div
                class="rounded-full h-6 w-6 flex shrink-0 shadow border border-gray-400 items-center justify-center bg-white -mt-2">
                <span class="font-bold">{{ props.currentPage <= totalPages ? props.currentPage : '🏁' }}</span>
                        </div>
        </div>
    </div>
</template>
