<script setup lang="ts">
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";

const props = withDefaults(
  defineProps<{
    email?: string;
    isInvited?: boolean;
    isInvitedForReview?: boolean;
    team?: string;
  }>(),
  {
    email: "",
    isInvited: false,
    isInvitedForReview: false,
  }
);

const form = useForm({
  name: "",
  email: props.email,
  password: "",
  password_confirmation: "",
  terms: false,
});

const submit = () => {
  form.post(route("register"), {
    onFinish: () => form.reset("password", "password_confirmation"),
  });
};
</script>

<template>
  <Head title="Register" />

  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>
    <div class="my-6 text-center">
      <p class="text-gray-500">registreren voor</p>
      <h2 class="text-3xl mt-3 mb-12 text-gray-900">Talentenmodule</h2>
    </div>
    <div v-if="isInvited" class="my-6 text-center">
      <p class="text-gray-500">Je bent uitgenodigd in het team</p>
      <h3 class="text-xl my-3 font-medium text-gray-900">
        {{ team }}
      </h3>
    </div>
    <div v-if="isInvitedForReview" class="my-6 text-center">
      <p class="text-gray-500">Je bent uitgenodigd om het team te beoordelen.</p>
      <h3 class="text-xl my-3 font-medium text-gray-900">
        {{ team }}
      </h3>
    </div>
    <form @submit.prevent="submit">
      <div>
        <InputLabel for="name" value="Naam" />
        <TextInput
                   id="name"
                   v-model="form.name"
                   type="text"
                   class="mt-1 block w-full"
                   required
                   autofocus
                   autocomplete="name" />
        <InputError class="mt-2" :message="form.errors.name" />
      </div>

      <div class="mt-4" v-if="!isInvited && !isInvitedForReview">
        <InputLabel for="email" value="Email" />
        <TextInput
                   id="email"
                   v-model="form.email"
                   type="email"
                   class="mt-1 block w-full"
                   required />
        <InputError class="mt-2" :message="form.errors.email" />
      </div>

      <div class="mt-4">
        <InputLabel for="password" value="Wachtwoord" />
        <TextInput
                   id="password"
                   v-model="form.password"
                   type="password"
                   class="mt-1 block w-full"
                   required
                   autocomplete="new-password" />
        <InputError class="mt-2" :message="form.errors.password" />
      </div>

      <div class="mt-4">
        <InputLabel for="password_confirmation" value="Herhaal wachtwoord" />
        <TextInput
                   id="password_confirmation"
                   v-model="form.password_confirmation"
                   type="password"
                   class="mt-1 block w-full"
                   required
                   autocomplete="new-password" />
        <InputError class="mt-2" :message="form.errors.password_confirmation" />
      </div>

      <div v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature" class="mt-4">
        <InputLabel for="terms">
          <div class="flex items-center">
            <Checkbox id="terms" v-model:checked="form.terms" name="terms" required />

            <div class="ml-2">
              I agree to the
              <a
                 target="_blank"
                 :href="route('terms.show')"
                 class="underline text-sm text-gray-600 hover:text-gray-900">Terms of Service</a>
              and
              <a
                 target="_blank"
                 :href="route('policy.show')"
                 class="underline text-sm text-gray-600 hover:text-gray-900">Privacy Policy</a>
            </div>
          </div>
          <InputError class="mt-2" :message="form.errors.terms" />
        </InputLabel>
      </div>

      <div class="flex items-center justify-end mt-6">
        <Link
              :href="route('login')"
              class="underline text-sm text-gray-600 hover:text-gray-900">
        Al geregistreerd?
        </Link>

        <PrimaryButton
                       class="ml-4"
                       :class="{ 'opacity-25': form.processing }"
                       :disabled="form.processing">
          Registreer
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>
</template>
