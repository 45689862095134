<script lang="ts" setup>
import AppLayout from '@/Layouts/ManagerLayout.vue';
import PotentialPerformance from '@/Components/graphs/PotentialPerformance.vue';
import BehaviourGraph from '../../Components/graphs/BehaviourGraph.vue';
import BehaviourAmounts from '../../Components/graphs/BehaviourAmounts.vue';
import CapacityAmounts from '../../Components/graphs/CapacityAmounts.vue';
import MotivatorGraph from '../../Components/graphs/MotivatorGraph.vue';
import TalentGraphs from '../../Components/TalentGraphs.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { Tooltip } from '@programic/vue3-tooltip';
import { potentialPerformance as potentialPerformanceText, dominantBehaviour as dominantBehaviourText, primaryMotivators as primaryMotivatorDescription } from '../../texts';
import { computed, defineProps, ref } from 'vue';
import { color } from 'chart.js/helpers';
import { lowerCase } from 'lodash';

interface Team {
    name: string
    id: number
    owner: object
    members: Array<object>
    company: object
}

interface PotentialPerformance {
    category: string
    performance: number
    potential: number
    
}

interface BehaviourPosition {
    name: string
    label: string
}

const props = defineProps<{
    team: Team
    potential_performance: Array<PotentialPerformance>
    behaviour_score: object
    behaviour_colors: object
    categories: object
    all_behaviour_positions: Array<BehaviourPosition>
    motivator_score: { [key: string]: number }
    motivator_colors: { [key: string]: string }
    capacity_amounts: { [key: string]: number }
    talents: { [key: string]: number }
}>();
// amount of potential performance per category
const potentialPerformanceTotals = computed(() => {
    return Object.keys(potentialPerformanceText).map((key) => {
        return [key, 
            props.potential_performance.filter((item) => item.category === key).length

        ];
    })
    .filter(([_, amount]) => amount > 0)
    .sort((a, b) => b[1] - a[1]);
});

const MotivatorScoreSorted = computed(() => {
    return Object.entries(props.motivator_score)
        .sort((a, b) => b[1] - a[1])
        .map(([key, _]) => key);
});

const mostDominantColor = computed(() => {
    return Object.entries(props.behaviour_score.dominantColours)
        .sort((a, b) => b[1] - a[1])
        .map(([key, _]) => key)
        .shift();
});

const allBehaviourPositions = computed(() => {
    return props.all_behaviour_positions.map((item, i) => {
        return {
            ...item,
            label: i+1,
            name: item.name
        };
    });
});

const isBigTeam = computed(() => {
    return props.potential_performance.length > 20
});

const behaviourListCollapsed = ref(true);

</script>

<template>
    <AppLayout title="Dashboard">
        <template #header>
            <div class="my-2 flex flex-wrap gap-4 justify-between">
                <h2 class="block font-semibold text-xl text-gray-800 leading-tight">
                    Overzicht {{ team.name }}
                </h2>
                <div>
                <PrimaryButton class="my-2 mr-5 text-xs" type="link" :href="route('teams.show', team.id)">
                    Bekijk persoonsoverzichten
                </PrimaryButton>
                <PrimaryButton v-if="!isBigTeam" class="my-2 " type="link" :href="route('teams.overzicht', team.id)">
                    Download overzicht
                </PrimaryButton>
                </div>
            </div>
        </template>

        <div class="mb-4">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <article class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6">
                    <h3 class="text-center mb-4">
                        <Tooltip :trigger="'hover'" type="light" placement="bottom"
                                 title="In het talentgrid ziet u de P-factorscore afgezet tegen de Prestatie score. De P-factor score is wat u medewerker in huis heeft en is gemeten aan de hand van de analyses die de medewerker heeft ingevuld. De Prestatiescore is het totaal van hoe u de prestaties van de medewerker heeft beoordeelt.">
                            <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-2 mt-auto h-6 text-blue-500" />
                        </Tooltip>
                        <span
                              class="inline-block rounded-lg text-white font-semibold uppercase bg-blue-500 p-1 w-1/2 shadow">
                              Talentgrid (vlootschouw)
                        </span>
                    </h3>
                    <PotentialPerformance :data="potential_performance" :categories="categories"></PotentialPerformance>
                    <section class="lg:w-3/4">
                    <h4 class="font-semibold text-lg mb-1"> Uw team bestaat uit </h4>
                        <template v-for="([key, amount]) in potentialPerformanceTotals" :key="key">
                            <p class="mb-2"><span class="p-1 rounded font-semibold" :style="{
                                background: categories[key]
                            }">
                            {{ potentialPerformanceText[key].title }}
                            </span> 
                            {{ potentialPerformanceText[key].description }}
                            </p>
                            </template> 
                    </section>

                </article>
                <article class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6 mt-9">
                    <h3 class="text-center mb-4">
                        <Tooltip :trigger="'hover'" type="light" placement="bottom"
                                 title="In het linker figuur ziet u een overzicht van alle basisstijlen in uw organisatie/team. U kunt dit als input gebruiken voor een sterkte/zwakte analyse. Waar ligt het zwaartepunt binnen uw organisatie/team, welke gedragsstijl mist u.">
                            <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-2 mt-auto h-6 text-blue-500" />
                        </Tooltip>
                        <span
                              class="inline-block rounded-lg text-white font-semibold uppercase bg-blue-500 p-1 w-1/2 shadow">
                            gedrag
                        </span>
                    </h3>
                    <p>
                    In de gedragsanalyse onderscheiden we vier gedragsaspecten (rood, geel, groen en blauw). 
                    In het positieblok zijn de gedragsstijlen van iedere medewerker geplot. 
                    Hierbij is het belangrijk om na te gaan of de stijlen overeenkomen met wat er nodig is in het team. 
                    In het cirkeldiagram word het totaal aantal van de hoogst gescoorde gedragsaspect per persoon weergegeven. 
                    Daarbij is het belangrijk om te kijken welk gedragsaspect overheersend is. 
                    </p>
                    <div class="flex flex-wrap flex-between gap-9 mt-9">
                        <div id="behaviour-users" class="mt-20">
                            <h3 class="font-semibold text-lg ml-6 w-full">Teamleden
                            </h3>
                            <div v-for="({ name, label }, index) in allBehaviourPositions" :key="label"
                                 class="mb-1 border-b border-gray-100"
                                 :class="{ hidden: index > 7 && behaviourListCollapsed }">
                                <span class="w-6">
                                    {{ label }}
                                </span>
                                {{ name }}
                            </div>
                            <button v-if="allBehaviourPositions.length > 8" 
                                class="hidden lg:block w-full text-blue-800"
                                @click="behaviourListCollapsed  = !behaviourListCollapsed">
                                <span v-if="behaviourListCollapsed">Toon alles</span>
                                <span v-else>Verberg</span>
                            </button>
                        </div>
                        <section class="w-[400px]">
                            <p class="font-semibold mb-3 text-center">Positieblok</p>
                            <BehaviourGraph :data="allBehaviourPositions"></BehaviourGraph>
                        </section>
                        <section class="w-1/3">
                            <p class="font-semibold mb-3 text-center">Verdeling</p>
                            <BehaviourAmounts class="mt-12" :data="Object.values(behaviour_score.dominantColours)"
                                              :colors="behaviour_colors">
                            </BehaviourAmounts>
                        </section>
                        <section class="lg:w-3/4">
                        <p>
                          <span class="p-1 rounded font-semibold" :style="{
                                background: behaviour_colors[mostDominantColor]
                            }">
                          {{ dominantBehaviourText[mostDominantColor].title }} 
                          </span>   
                          {{ dominantBehaviourText[mostDominantColor].description  }}

                        </p>
                        </section>
                    </div>
                </article>
                <article class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6 mt-9">
                    <h3 class="text-center mb-4">
                        <Tooltip :trigger="'hover'" type="light" placement="bottom"
                                 title="Hier ziet u een overzicht van de gemiddelde scores op de drijfveren binnen uw organisatie/team. U
                        kunt dit als input gebruiken voor een sterkte/zwakte analyse. Waar ligt het zwaartepunt binnen uw
                        organisatie/team, welke drijfveer mist u.">
                            <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-2 mt-auto h-6 text-blue-500" />
                        </Tooltip>
                        <span
                              class="inline-block rounded-lg text-white font-semibold uppercase bg-blue-500 p-1 w-1/2 shadow">
                            drijfveren
                        </span>
                    </h3>
                   <h4 class="font-semibold text-lg mb-1">
                    Toelichting 
                   </h4>
                   <p>
                        In de figuur zijn de gemiddelde scores op de drijfveren van het team weergegeven. Daarbij is het belangrijk om te kijken welke twee drijfveren overheersend zijn, welke twee situationeel en welke twee neutraal. In het team zijn de overheersende drijfveren 
                        <mark class="bg-white font-medium" :style="{color: motivator_colors[lowerCase(MotivatorScoreSorted[0])]}"> 
                        {{ MotivatorScoreSorted[0] }} 
                        </mark> en 
                        <mark class="bg-white font-medium" :style="{color: motivator_colors[lowerCase(MotivatorScoreSorted[1])]}"> 
                        {{ MotivatorScoreSorted[1] }} 
                        </mark>
                        Dit betekent dat het team vooral door deze twee drijfveren gemotiveerd wordt en op basis van deze twee drijfveren beslissingen neemt. 
                    </p>
                    <p class="mt-1"><span class="font-semibold">
                    Deze combinatie houdt in dat 
                    </span>
                    {{ primaryMotivatorDescription(MotivatorScoreSorted) }} </p>
                    <MotivatorGraph class="mx-9 my-9" :data="motivator_score" :colors="motivator_colors"></MotivatorGraph>
                </article>
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6 mt-9">
                    <h3 class="text-center mb-4">
                        <Tooltip :trigger="'hover'" type="light" placement="bottom"
                                 title="Hier ziet u een overzicht van de top 5 competenties binnen uw organisatie/team.">
                            <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-2 mt-auto h-6 text-blue-500" />
                        </Tooltip>
                        <span
                              class="inline-block rounded-lg text-white font-semibold uppercase bg-blue-500 p-1 w-1/2 shadow">
                            Talenten
                        </span>
                    </h3>
                    <p>
                        Hier ziet u een overzicht van de top 5 competenties binnen uw organisatie/team.
                    </p>
                    <TalentGraphs class="mx-9 my-9" :data="talents"></TalentGraphs>
                </div>
                <div class="bg-white overflow-hidden shadow-xl sm:rounded-lg p-6 mt-9">
                    <h3 class="text-center mb-4">
                        <Tooltip :trigger="'hover'" type="light" placement="bottom"
                                 title="Hier ziet u de verdeling van het werk/denk niveau binnen uw organisatie/team">
                            <font-awesome-icon :icon="['fas', 'circle-info']" class="mr-2 mt-auto h-6 text-blue-500" />
                        </Tooltip>
                        <span
                              class="inline-block rounded-lg text-white font-semibold uppercase bg-blue-500 p-1 w-1/2 shadow">
                            werk/denk niveau
                        </span>
                    </h3>
                    <p>
                         Hier vindt u een overzicht van de aantallen medewerkers ingedeeld naar gemiddeld werk/denk niveau. Om werk/denkniveau te bepalen is ofwel opleidingsniveau uitgevraagd, dan wel een capaciteitentest ingevuld.
                    </p>
                    <CapacityAmounts class="mx-9 my-9" :data="capacity_amounts"></CapacityAmounts>
                </div>
            </div>
        </div>
    </AppLayout>
</template>