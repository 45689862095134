<script setup lang="ts">
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import ApplicationMark from '@/Components/ApplicationMark.vue';
import { directive as vClickAway } from 'vue3-click-away';

const logout = () => {
    router.post(route("logout"));
};

const open = ref(false);
function close() {
    open.value = false;
}
</script>
<template>
    <nav
         class="top-navigation fixed text-white top-0 left-0 right-0 bg-blue-1000 px-[16px] py-[16px] border-b border-blue-50"
         style="background-image:radial-gradient(ellipse at 400px -200px, rgba(255, 255, 255, 0.25),rgba(255,255,255,0 ) 90%);">
        <a class="sr-only" href="#content-body">Naar content</a>
        <ul class="align-center flex justify-start">
            <li class="self-center mb-[-5px]"><a class="block text-xl font-bold leading-9" href="/">
                    <ApplicationMark class="inline-block mr-2" /> Talentenmodule
                </a>
            </li>
            <li class="ml-4 self-center">
                <slot />
            </li>
            <!-- align at end -->
            <li class="ml-auto">
                <!-- dropdown button -->
                <button class="text-xs" @click.stop="open = !open">
                    <img class="w-10 h-10 rounded-full" :src="$page.props.auth.user.profile_photo_url"
                         :alt="$page.props.auth.user.name">
                </button>
                <!-- dropdown menu -->
                <ul class="absolute right-0 w-32 bg-white text-gray-800 shadow-md rounded-md p-2" v-show="open"
                    v-click-away="close">
                    <li><a class="block text-base" :href="route('profile.show')">Profiel</a></li>
                    <li class="leading-loose mt-3">
                        <form method="POST" @submit.prevent="logout">
                            <button class="text-base"><font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
                                Uitloggen
                            </button>
                        </form>
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>
<style lang="css">
.top-navigation {
    height: var(--menu-height, 72px);
}
</style>