/** 
 * Shuffles an array with a seed to get the same order with the same seed
 * Modification of Fisher-Yates shuffle: https://bost.ocks.org/mike/shuffle/
 *
 * See https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
 */

if (import.meta.vitest) {
    const { it, expect } = import.meta.vitest
    it('should shuffle an array with a seed consistently', () => {
        expect(seededShuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 1)).toEqual([ 3, 1, 10, 4, 6, 5, 7, 2, 9, 8 ]);
        expect(seededShuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 2)).toEqual([ 4, 9, 1, 3, 5, 7, 6, 8, 2, 10 ]);
        expect(seededShuffle([1, 2, 3, 4, 5, 6, 7, 8, 9, 10], 1000000)).toEqual([ 2, 4, 9, 10, 7, 8, 6, 3, 5, 1 ]);
    })
}

export function seededShuffle(array: Array<any>, seed: number): Array<any> {
    // <-- ADDED ARGUMENT
    var m = array.length,
        t,
        i;

    array = [...array];

    // While there remain elements to shuffle…
    while (m) {
        // Pick a remaining element…
        i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed; // <-- ADDED LINE
    }

    return array;
}

// Returns a random number between 0 and 1
function random(seed: number): number {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export default {
    seededShuffle,
}
