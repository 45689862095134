<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import ActionSection from '@/Components/ActionSection.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import DangerButton from '@/Components/DangerButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';

const props = defineProps({
    team: Object,
});

const name = ref(props.team.name);

const confirmingTeamDeletion = ref(false);
const form = useForm({
    deleteTeam: false
});

const confirmTeamDeletion = () => {
    confirmingTeamDeletion.value = true;
};

const deleteTeam = () => {
    form.delete(route('teams.destroy', props.team), {
        errorBag: 'deleteTeam',
    });
};
</script>

<template>
    <ActionSection>
        <template #title> Verwijder {{ name }} </template>

        <template #description> Verwijder {{ name }} permanent. </template>

        <template #content>
            <div class="max-w-xl text-sm text-gray-600">
                Wanneer je {{ name }} verwijderd, worden alle data in het team verwijderd.
            </div>

            <div class="mt-5">
                <DangerButton @click="confirmTeamDeletion"> Verwijder Team </DangerButton>
            </div>

            <!-- Delete Team Confirmation Modal -->
            <ConfirmationModal
                               :show="confirmingTeamDeletion"
                               @close="confirmingTeamDeletion = false">
                <template #title> Verwijder {{ name }} </template>

                <template #content>
                    Weet je zeker dat je het team wilt verwijderen?
                </template>

                <template #footer>
                    <SecondaryButton @click="confirmingTeamDeletion = false">
                        Annuleer
                    </SecondaryButton>

                    <DangerButton
                                  class="ml-3"
                                  :class="{ 'opacity-25': form.processing }"
                                  :disabled="form.processing"
                                  @click="deleteTeam">
                        Verwijder Team
                    </DangerButton>
                </template>
            </ConfirmationModal>
        </template>
    </ActionSection>
</template>
