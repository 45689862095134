<script setup lang="ts">
import { computed } from 'vue';
import SectionProgressBar from './SectionProgressBar.vue';
const props = defineProps<{ sections: { name: string }[]; currentSectionIndex: number }>();

const section = computed(() => props.sections[props.currentSectionIndex]);

type MessageType = 'single' | 'first' | 'middle' | 'second-last' | 'last';
const messageType = computed<MessageType>(() => {
    if (props.sections.length === 1) {
        return 'single';
    }
    else if (props.currentSectionIndex === 0) {
        return 'first';
    } else if (props.currentSectionIndex === props.sections.length - 2) {
        return 'second-last'
    } else if (props.currentSectionIndex === props.sections.length - 1) {
        return 'last';
    } else {
        return 'middle';
    }
});

const currentSectionName = computed<string>(() => props.sections[props.currentSectionIndex].name);
const nextSectionName = computed<string>(() => props.sections[props.currentSectionIndex + 1].name);
</script>
<template>
    <div class="mb-12">
        <p v-if="messageType === 'single'" class="mx-4 mt-3 mb-12">
            Je hebt de vragenlijst van
            {{ currentSectionName.toLowerCase() }} afgerond. </p>
        <p v-if="messageType === 'first'" class="mx-4 mt-3 mb-12">
        <div class="w-full flex justify-center my-12">
            <font-awesome-icon icon="fa-solid fa-mug-hot" class="w-16 h-16" />
        </div>
        Het onderdeel {{ currentSectionName.toLocaleLowerCase() }} heb je afgerond. Je kunt door met het onderdeel {{
            nextSectionName.toLocaleLowerCase()
        }}. Eventueel kun je kort pauzeren voor je doorgaat met het onderdeel
        drijfveren.
        </p>
        <p v-if="messageType === 'middle'" class="mx-4 mt-3 mb-12">
        <div class="w-full flex justify-center my-12">
            <font-awesome-icon icon="fa-solid fa-mug-hot" class="w-16 h-16" />
        </div>
        Het onderdeel {{ currentSectionName.toLocaleLowerCase() }} heb je afgerond. Je kunt door met het onderdeel {{
            nextSectionName.toLocaleLowerCase()
        }}. Eventueel kun je kort pauzeren voor je doorgaat met het onderdeel
        drijfveren.
        </p>
        <p v-if="messageType === 'second-last'" class="mx-4 mt-3 mb-12">
        <div class="w-full flex justify-center my-12">
            <font-awesome-icon icon="fa-solid fa-mug-hot" class="w-16 h-16" />
        </div>
        Het onderdeel {{ currentSectionName.toLocaleLowerCase() }} heb je afgerond. Je kunt door met het laatste
        onderdeel, {{ nextSectionName.toLocaleLowerCase() }}. Eventueel kun je kort pauzeren voor je doorgaat met het
        laatste onderdeel.
        </p>
        <p v-if="messageType === 'last'" class="mx-4 mt-3 mb-12">
            <div class="w-full flex justify-center my-12">
                <img src="/confetti.png" class="h-16 transform scale -scale-x-100" />
                <img src="/confetti.png" class="h-16" />
            </div>
            Je hebt het onderdeel {{ currentSectionName.toLocaleLowerCase() }} afgerond. Klik op voltooien om verder te
            gaan.
        </p>
        <slot />
        </div>
</template>