<script setup lang="ts">
import { useForm, usePage, router } from "@inertiajs/vue3";
import PrimaryButton from "../../../Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";
import DialogModal from "@/Components/DialogModal.vue";
import InputLabel from "@/Components/InputLabel.vue";
import InputError from "@/Components/InputError.vue";

const props = withDefaults(
    defineProps<{ show: boolean; maxWidth: string; closeable: boolean }>(),
    {
        show: false,
        maxWidth: "2xl",
        closeable: true,
    }
);

const page = usePage();
const createTeamForm = useForm({
    name: "",
    company_id: page.props.auth.user.current_company_id,
});

const submitForm = () => {
    createTeamForm.post(route("teams.store"), {
        onSuccess: () => {
            createTeamForm.reset("name");
            close();
            router.visit(route('teams'), { only: ['teams'] })
        },
    });
};

const emit = defineEmits(["close"]);
const close = () => {
    emit("close");
};
</script>
<template>
    <div>
        <form @submit.prevent="submitForm">
            <DialogModal
                         :show="show"
                         :max-width="maxWidth"
                         :closeable="closeable"
                         @close="close">
                <template #title>Maak team</template>
                <template #content>
                    <InputLabel for="name">Team naam</InputLabel>
                    <TextInput
                               id="name"
                               v-model="createTeamForm.name"
                               type="text"
                               @keyup.enter="submitForm"
                               required
                               class="mt-1 block w-full" />
                    <InputError class="mt-2" :message="createTeamForm.errors.name" />
                </template>
                <template #footer>
                    <PrimaryButton @click="submitForm" type="submit">Maak team</PrimaryButton>
                </template>
            </DialogModal>
        </form>
    </div>
</template>
