<script setup lang="ts">
import { computed } from 'vue'
const props = defineProps<{ title: string }>()

const colors = computed(() => {
    switch (props.title) {
        case 'Onvoldoende':
            return {
                left: '#EA2931',
                right: '#D91A21'
            }

        case 'Matig':
            return {
                left: '#F7AF21',
                right: '#EAA61F'
            }

        case 'Voldoende':
            return {
                left: '#F4E051',
                right: '#E8D54D'
            }

        case 'Ruim voldoende':
            return {
                left: '#B7E869',
                right: '#9FCA5B'
            }

        case 'Goed':
            return {
                left: '#009245',
                right: '#007F3C'
            }

        default:
            break;
    }
})

const smilePath = computed(() => {
    switch (props.title) {
        case 'Goed':
            return "M125.5 113C122 120.833 109.3 136.5 86.5 136.5C63.7 136.5 50 120.833 46 113";
        case 'Onvoldoende':
            return "M46 136.5C49.5 128.667 62.2 113 85 113C107.8 113 121.5 128.667 125.5 136.5";
        case 'Voldoende':
            return "M114.5 126.999C114.5 126.999 102 136.499 86.5 136.499C67 136.499 57 126.999 57 126.999"
        case 'Matig':
            return "M115 130.999C115 130.999 102 130.999 86.5 130.999C67 130.999 57 130.999 57 130.999";
        case 'Ruim voldoende':
            return "M121.5 119.499C118 127.332 101.5 136.499 86 136.499C66.5 136.499 54 127.832 50 119.999"

        default:
            break;
    }

})

</script>
<template>
    <svg width="173" height="173" viewBox="0 0 173 173" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path class="transition-colors" d="M86.5 86.5V173C38.7274 173 0 134.273 0 86.5C0 38.7274 38.7274 0 86.5 0V86.5Z"
              fill="#F7AF21" :style="{ fill: colors.left }" />
        <path d="M173 86.5C173 134.273 134.273 173 86.5 173V86.5V0C134.273 0 173 38.7274 173 86.5Z" fill="#EAA61F"
              :style="{ fill: colors.right }" />
        <circle cx="52.5" cy="57.5" r="14.5" fill="#FFF" />
        <circle cx="119.5" cy="57.5" r="14.5" fill="#FFF" />
        <path :d="smilePath" stroke="#FFF"
              stroke-width="9" stroke-linecap="round" />
    </svg>
</template>