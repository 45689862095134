<script setup lang="ts">
import { ref } from 'vue';
import ManagerLayout from "@/Layouts/MemberLayout.vue";
import Card from "../Components/Card.vue";
import PrimaryButton from "../Components/PrimaryButtonWhite.vue";
import { formatDate } from "../composables/formatDate.js";
import useGradients from '../composables/gradients';
import { Link, useForm, usePage } from "@inertiajs/vue3";

const { assessments, reports, title, hasReports, hasJobProfile } = defineProps<{ assessments: Array<object>, reports: Array<{ name: string, slug: string, description: string }>, title: string, hasReports: boolean, hasJobProfile: boolean }>()
const date = s => formatDate(s);
console.log(reports);

const { linearGradient } = useGradients();


// fake status for download
const downloading = ref(false);
const downloadText = ref('verzamelen');
const downloadWhat = ref('');

function runPdf(slug) {
    if (downloading.value) {
        return;
    }
    downloading.value = true;
    downloadWhat.value = slug;
    collectPdf();
}

function download(url, filename) {
    axios({
        url: url,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    });
}


function showResults() {
    if (hasReports === 'false' || hasJobProfile === 'false') {
        return;
    }
    const url = route('results.show', usePage().props.auth.user.id);
    window.open(url, '_blank');
}

function downloadPdf() {
    const url = route('pdf.download', downloadWhat.value);
    download(url, 'rapport_' + downloadWhat.value + '.pdf')
    downloading.value = false;
    downloadText.value = 'verzamelen';
    downloadWhat.value = '';
}

function fireApiPdf() {
    downloadText.value = 'schrijven';
    const url = route('pdf.post', downloadWhat.value);
    // send request to fire api to create pdf
    // set status to 'schrijven'
    const fireForm = useForm({
        action: 'fire',
    });
    fireForm.post(url, {
        preserveScroll: true,
        onSuccess: () => {
            downloadText.value = 'verzenden';
            setTimeout(() => {
                downloadPdf();
            }, 1000);
        },
        onError: () => {
            console.log('api failed')
        }
    })
}

function collectPdf() {
    downloadText.value = 'verzamelen'
    const url = route('pdf.post', downloadWhat.value);
    const collectForm = useForm({
        action: 'collect',
    });
    collectForm.post(url, {
        preserveScroll: true,
        onSuccess: () => {
            fireApiPdf();
        },
        onError: () => {
            console.log('collect failed')
        }
    })
}


function checkCompleted({ current_status }) {
    return current_status === 'completed';
}

function isShort(slug) {
    return slug === 'opleidingsniveau';
}
</script>

<template>
    <ManagerLayout :title="title">
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">{{ title }}</h2>
        </template>


        <div class="bg-white p-2 pt-4 border rounded shadow-sm">
            <h3 class="sm:px-6 lg:px-8 w-full font-medium text-lg my-3">Assessments</h3>
            <p class="sm:px-6 lg:px-8">Hier staan de assessments die beschikbaar zijn voor jou. </p>
            <div class="full flex gap-12 mx-auto pt-6 pb-10 sm:px-6 lg:px-8 shrink-0 grow-0 flex-wrap">
                <template v-for="({ status, name, updated_at, slug }, key) in assessments" :key="key">
                    <Link class="sm:w-3/4 lg:w-5/12 max-w-xs"
                          :href="checkCompleted(status) ? '#' : route('assessments.show', slug)">
                    <Card class="text-white" :style="{
        'background-image': linearGradient('185deg', slug)
    }">
                        <font-awesome-icon class="w-8 h-8" :class="{
        'text-green': checkCompleted(status)
        , 'text-white': checkCompleted(status) === false
    }" icon="fa-solid fa-circle-check fa-xl"></font-awesome-icon>
                        <h2 class="text-md text-white mb-1">{{ name }}</h2>
                        <p class="mt-1 text-xs text-white">uitgenodigd op: {{ date(status.invited_at) }}</p>
                        <div class="mt-4">
                            <PrimaryButton v-if="checkCompleted(status) === false" type="link">
                                {{ isShort(slug) ? "Vul in" : "Start de test" }}
                            </PrimaryButton>
                            <div v-else>
                                <p>bekijk resultaten in de rapporten.</p>
                            </div>

                        </div>
                    </Card>
                    </Link>
                </template>
            </div>
            <div>
                <h3 class="sm:px-6 lg:px-8 w-full font-medium text-lg my-3">Overzichten en rapporten</h3>
                <p class="sm:px-6 lg:px-8">Hier staan de overzichten en rapporten die beschikbaar zijn.</p>
                <div
                     class="w-full flex items-stretch gap-12 mx-auto pt-6 pb-10 sm:px-6 lg:px-8 shrink-0 grow-0 flex-wrap">
                    <a @click="showResults()" class="w-full md:w-3/4 lg:w-5/12 sm:max-w-sm">
                        <Card class="text-white h-full flex flex-nowrap flex-col" :style="{
        'background-image': linearGradient('185deg', '_col')
    }">
                            <img class="mx-auto my-3 w-16 h-16" :src="'/' + 'drijfveren' + '.png'" />
                            <h2 class="text-lg font-medium text-white mb-3">persoonsoverzicht</h2>
                            <p class="mb-6">Bekijk hier de resultaten van het ontwikkelassessment</p>
                            <div class="mt-auto">
                                <PrimaryButton class="bg-gray-300" v-if="downloading" type="button" disabled>
                                    Bekijk overzicht
                                </PrimaryButton>
                                <PrimaryButton v-else type="button">Bekijk overzicht
                                </PrimaryButton>


                            </div>
                        </Card>

                    </a>
                    <template v-for="({ name, slug, description }, key) in reports" :key="key">
                        <a @click="runPdf(slug)" class="w-full md:w-3/4 lg:w-5/12 sm:max-w-sm">
                            <Card class="text-white h-full flex flex-nowrap flex-col" :style="{
        'background-image': linearGradient('185deg', slug + '_col')
    }">
                                <img class="mx-auto my-3 w-16 h-16" :src="'/' + slug + '.png'" />
                                <h2 class="text-lg font-medium text-white mb-3">{{ name }}</h2>
                                <p class="mb-6">{{ description }}</p>
                                <div class="mt-auto">
                                    <p v-if="slug === downloadWhat" class="mb-3">
                                        <font-awesome-icon icon="fa-solid fa-spinner" spin
                                                           class="w-6 h-6 mr-3 text-white" /> {{ downloadText }}
                                    </p>
                                    <PrimaryButton class="bg-gray-300" v-else-if="downloading" type="button" disabled>
                                        Bekijk rapport
                                    </PrimaryButton>
                                    <PrimaryButton v-else type="button">Bekijk rapport
                                    </PrimaryButton>


                                </div>
                            </Card>
                        </a>
                    </template>
                </div>
            </div>
        </div>
    </ManagerLayout>
</template>
