<script setup lang="ts">
import { computed } from 'vue';
import { Head, Link } from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import SectionProgressBar from '@/Components/SectionProgressBar.vue';
import TopNavigation from '../Components/TopNavigation.vue';
import TimelineNavigation from '../Components/TimelineNavigation.vue';
import SectionIntroduction from '../Components/SectionIntroduction.vue';
import useGradients from '../composables/gradients';

/* define props title, sections, totalPages, currentPage, currentSection */
const props = defineProps<{
    title: string;
    totalPages?: number;
    currentPage?: number;
    sections: { name: string, instruction_body: string, instruction_title: string }[];
    sectionIndex?: number;
    isIntro?: boolean;
    shortForm?: boolean;
}>();

/* define emits next, previous, finish */
const emit = defineEmits<{
    (event: 'next'): void;
    (event: 'previous'): void;
    (event: 'finish'): void;
}>();

const totalSections = props.sections ? props.sections.length : 1;

const { linearGradient } = useGradients();
const section = computed(() => props.sections ? props.sections[props.sectionIndex] : null);
</script>
<template>
    <Head :title="props.title" />

    <div class="h-screen bg-blue-50">
        <div class="flex h-full pt-[72px] flex-wrap">
            <!-- Page Heading -->
            <div class="w-full mt-2 lg:mx-auto max-w-7xl">
                <div class="mx-3 md:mx-6 ">
                    <header v-if="$slots.header" class="pl-4 pt-4 pb-2">
                        <slot name="header" />
                    </header>
                    <TimelineNavigation v-if="props.sections && !props.shortForm" :steps="props.sections"
                                        :currentStepIndex="props.sectionIndex" />
                    <SectionProgressBar v-if="props.currentPage && props.totalPages && !props.shortForm"
                                        :current-page="props.currentPage"
                                        :total-pages="props.totalPages" :total-sections="props.sections.length"
                                        :current-section="props.sectionIndex + 1" />
                </div>
                <main class="my-9 sm:mx-3 md:mx-6 ">
                    <slot name="help" />
                    <div class="p-4 bg-blue-1000 border sm:rounded-xl shadow-sm text-white"
                         :style="{ 'background-image': linearGradient('to bottom', section?.name + (isIntro ? '_intro' : '_bg')) }">
                        <slot />
                    </div>
                </main>
            </div>
            <!-- container that holds the next and previous buttons -->
            <div class="w-full px-[32px] h-[132px] justify-between justify-self-end mb-[60px] lg:mx-auto max-w-7xl">
                <slot name="previous" />
                <div class="float-right">
                    <slot name="next" />
                </div>
            </div>
        </div>
    </div>
    <Banner />
    <TopNavigation>

    </TopNavigation>
</template>