<script setup lang="ts">
import { computed, ref, onMounted, watch } from 'vue';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Item {
    label: string
    name: string
    x: number
    y: number
}

const props = defineProps<{
    data: Array<Item>
}>();


const image = new Image();
// load image directly as a string
image.src = "/img/position_block_nl.png";

const canvasSize = ref(400);
const padding = canvasSize.value / 8;
// create a plugin to draw the image on the canvas
const plugin = {
    id: 'customCanvasBackgroundImage',
    beforeDraw: (chart) => {
        if (image.complete) {
            const ctx = chart.ctx;
            const { top, left, width, height } = chart.chartArea;
            const x = 0 //left + width / 2 - image.width / 2;
            const y = 0 //top + height / 2 - image.height / 2;

            const padding = Math.round((width / 8) * 1.3333);
            ctx.drawImage(image, x, y, width + (padding * 2), height + (padding * 2));
        } else {
            image.onload = () => chart.draw();
        }
    }
};


const options = {
    // legend display false and load chartDatalabels plugin
    layout:
    {
        padding: padding
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#fff',
            titleColor: '#000',
            bodyColor: '#000',
            borderColor: '#999',
            callbacks: {
                label: function (context) {
                    return `${context.raw.label}: ${context.raw.name}`
                }
            }
        },
        datalabels: {
            // show label with key
            offset: 10,
            color: '#000',
            font: {
                size: 12,
                weight: 'bold'
            }
        }
    },
    scales: {
        x: {
            ticks: {
                stepSize: 4,
            },
            min: -42,
            max: 42,
            display: false,
        },
        y: {
            ticks: {
                stepSize: 4,
            },
            min: -42,
            max: 42,
            display: false,
        }
    }
}

const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})
const datasets = computed(() => {
    return [{ data: props.data }]
});

watch(datasets, () => {
    renderChart();
})

// watch componentsize and rerender chart
watch(canvasSize, () => {
    renderChart();
})


var canvas = ref(null);

var chart = ref(null);
const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxGap = canvas.value.getContext('2d');
    chart.value = new Chart(ctxGap, {
        plugins: [ChartDataLabels, plugin],
        type: 'scatter',
        options: options,
        data: {
            datasets: datasets.value
        }
    })
}

</script>
<template>
    <canvas ref="canvas" :width="canvasSize" :height="canvasSize"></canvas>
</template>