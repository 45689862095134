<script setup lang="ts">
import { defineProps, ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';


const props = defineProps<{
    data: {
        [key: string]: number
    },
    colors: {
        [key: string]: string
    }
}>();

console.log(props.data.value)
const labels = ref(Object.keys(props.data));
const score = ref(Object.values(props.data));
const colors = ref(labels.value.map((label) => props.colors[label.toLocaleLowerCase()] || '#000'))
const datasets = ref(
    [{
        // don't show label
        label: 'Drijfveren',
        // sorted by score (highest to lowest)
        data: score.value,
        backgroundColor: colors.value,
        borderRadius: 6,
    }]
)

const options =
{
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        y: {
            min: 0,
            max: 100,
            stepSize: 20,
            ticks: {
                padding: 10,
            }
        },
        x: {
            grid: {
                display: false,
            },
        }
    }
}


const canvas = ref(null);

const chart = ref(null);
const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxGap = canvas.value.getContext('2d');
    chart.value = new Chart(ctxGap, {
        type: 'bar',
        options: options,
        data: {
            labels: labels.value,
            datasets: datasets.value
        }
    })
}


const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})

</script>
<template>
    <div>
        <canvas ref="canvas" id="motivator-graph" width="400" height="230"></canvas>
    </div>
</template>