<script setup lang="ts">
import { computed, defineEmits, ref, withDefaults, onMounted } from 'vue';
import draggable from 'vuedraggable';

const props = withDefaults(defineProps<{
    question: string,
    answer?: Array<{ id: number, answer: string }>,
    options: Array<{ id: number, answer: string }>,
    singleColumn?: boolean,
    bgGradient?: string,
}>(), {
    answer: () => [],
    singleColumn: () => false,
    bgGradient: () => '',
});

const emit = defineEmits(['update:answer', 'update:options']);

const userOptions = ref<Array<{ id: number, answer: string }>>([...props.options]);

const userAnswer = computed({
    get() {
        return props.answer
    },
    set(val) { emit('update:answer', val) }
})

if (props.singleColumn) {
    userAnswer.value = [...userOptions.value]
    userOptions.value = [];
}

const drag = ref(false);
const optionsId = ref(crypto.randomUUID());
const answerId = ref(crypto.randomUUID());

const draggableComponentData = ref({
    tag: 'div',
    name: 'fade',
    class: 'bg-white rounded-lg shadow w-full px-6 pt-5 pb-2 bg-contain bg-no-repeat bg-center',
    style: {
        'background-image': props.bgGradient,
        'min-height': 'unset'
    }
})

const dragItemClass = ref("select-none cursor-move px-6 sm:pr-3 py-4 mb-5 text-left shadow rounded-lg flex flex-row justify-between leading-4 text-black text-sm bg-white")

const dragger = ref(null);
onMounted(() => {
    if (dragger.value) {
        draggableComponentData.value.style.minHeight = `${dragger.value.offsetHeight}px`;
    }
})


</script>
<template>
    <div class="w-full h-full flex flex-col">
        <div class="w-full flex flex-col select-none">
            <div class="w-full flex flex-row">
                <h1 class="text-black text-xl font-bold leading-casual pl-8">{{
                    question
                }} ...</h1>
            </div>
            <div class="w-full flex hidden" :class="{ 'md:flex': !singleColumn }">
                <h2 class="text-gray-600 w-1/2 text-sm font-bold leading-casual pl-8">
                    beschikbare opties
                </h2>
                <h2 class="text-gray-600 w-1/2 text-sm font-bold leading-casual pl-8">
                    gesorteerd op persoonlijke voorkeur
                </h2>
            </div>
        </div>
        <div class="w-full flex flex-row mt-3">
            <div ref="dragger" class="flex flex-row w-full bg-gray-white sortableWrapper gap-3">
                <draggable v-model="userOptions" v-if="!singleColumn" :item-key='optionsId'
                           :group="{ name: 'order', pull: true, put: false }"
                           @start="drag = true"
                           @end="drag = false" tag="transition-group" :component-data="draggableComponentData"
                           :class="{ 'md:block': !singleColumn }">
                    <template #item="{ element, index }">
                        <div :key="index"
                             :class="dragItemClass">
                            <div class="w-full">
                                <p class="my-auto w-11/12">{{ element.answer_title }}</p>
                                <i v-bind:class="{ 'fa-grip-lines': singleColumn, 'fa-arrows-alt': !singleColumn }"
                                   class="fas my-auto"></i>
                            </div>
                        </div>
                    </template>
                </draggable>

                <draggable v-model="userAnswer" :item-key='answerId' sort="true"
                           :group="{ name: 'order', pull: false, put: true }"
                           @start="drag = true"
                           @end="drag = false" tag="transition-group" :component-data="draggableComponentData">
                    <template #item="{ element, index }">
                        <div :key="index"
                             :class="dragItemClass">
                            <p class="my-auto">{{ element.answer_title }}</p>
                            <i v-bind:class="{ 'fa-grip-lines': singleColumn, 'fa-arrows-alt': !singleColumn }"
                               class="fas my-auto"></i>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
    </div>
</template>