<script setup>
import { onMounted, ref } from 'vue';

defineProps({
    modelValue: String,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <textarea
              ref="input"
              col="50"
              row="8"
              class="border-gray-300 bg-gray-50 focus:bg-white focus:border-blue-800 focus:ring focus:ring-blue-900 focus:ring-opacity-50 rounded-md shadow-sm"
              :value="modelValue"
              @input="$emit('update:modelValue', $event.target.value)"></textarea>
</template>
