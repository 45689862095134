<script setup lang="ts">
</script>
<template>
    <div class="m-3">
        <h3 class="text-lg font-medium mb-6">
            Voordat je begint met het ontwikkelassessment

        </h3>
        <p class="my-6">
            Je gaat zo het ontwikkelassessment invullen. Dit assessment bestaat uit drie onderdelen: competenties, drijfveren en
            gedragsstijl.
            Aan het begin van ieder onderdeel wordt aangegeven hoe je de items kunt invullen.
        </p>
        
        <p class="mb-6">Verder is het goed om het volgende te weten:</p>
        <ul class="list-disc  ml-6 mb-6">
            <li>
                het invullen van deze vragenlijst duurt in totaal zo'n 45 minuten;
            </li>
            <li>
                je kan tussen de onderdelen kort pauzeren voor je doorgaat;
            </li>
            <li>
                je krijgt het beste resultaat als je je in een stille omgeving bevindt waar je ongestoord de analyse kan maken.
            </li>
        </ul>

    </div>
</template>