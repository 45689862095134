<script setup lang="ts">
import { useForm, usePage, router } from '@inertiajs/vue3';
import PrimaryButton from '../../../Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import DialogModal from '@/Components/DialogModal.vue';
import InputLabel from '@/Components/InputLabel.vue';
import InputError from '@/Components/InputError.vue';

const props = withDefaults(
    defineProps<{ show: boolean, maxWidth: string, closeable: boolean }>()
    , {
        show: false,
        maxWidth: '2xl',
        closeable: true,
    });

const page = usePage();
const createCompanyForm = useForm(
    {
        name: "",
        user_id: page.props.auth.user.id,
    },
);

const submitForm = () => {
    createCompanyForm.post(route('companies.store'), {
        onSuccess: () => {
            createCompanyForm.reset('name');
            close();
            router.visit(route('teams.index'), { only: ['companies'] })
        },
        onError: () => {
        },
    });
};

const emit = defineEmits(['close']);
const close = () => {
    emit('close');
};

</script>
<template>
    <div>
        <DialogModal
                     :show="show"
                     :max-width="maxWidth"
                     :closeable="closeable"
                     @close="close">
            <template #title>Maak bedrijf</template>
            <template #content>
                <InputLabel for="name">Bedrijf</InputLabel>
                <TextInput id="name" v-model="createCompanyForm.name" type="text" required
                           class="mt-1 block w-full" />
                <InputError class="mt-2" :message="createCompanyForm.errors.name" />
            </template>
            <template #footer>
                <PrimaryButton @click="submitForm">Maak bedrijf</PrimaryButton>
            </template>
        </DialogModal>
    </div>
</template>