<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    href: String,
    active: Boolean,
    current: Boolean,
    icon: String,
    as: String
});

const classes = computed(() => {
    return props.active
        ? 'nav-link focus:outline-none focus:border-blue-800'
        : 'nav-link border-transparent text-white focus:outline-none focus:text-gray-700';
});
</script>

<template>
    <li class="flex-col justify-start align" :current="current" :class="classes">
        <div class="flex flex-nowrap pl-3 pr-1 pb-4 pt-4 w-full">
            <button v-if="as === 'button'" class="w-full text-left">
                <span class="mr-2">
                    <font-awesome-icon :icon="icon"></font-awesome-icon>
                </span>
                <span class="text hidden md:inline-block">
                    <slot />
                </span>
            </button>
            <Link v-else :href="href" class="w-full">
            <span class="mr-2">
                <font-awesome-icon v-if="icon" :icon="icon"></font-awesome-icon>
            </span>
            <span class="text hidden md:inline-block">
                <slot />
            </span>
            </Link>
        </div>
        <ul v-if="$slots.subitems" class="w-full pl-7">
            <slot name="subitems" />

        </ul>

    </li>
</template>
<style lang="css">
.nav-link {
    @apply text-gray-50 inline-flex border border-transparent w-11/12 text-base font-medium leading-5 transition;
}

.nav-link[current="true"]>div {
    @apply bg-blue-800 text-white w-11/12 rounded-r-sm;
}

.closedDesktop .text {
    display: none;
}

.openMobile .text {
    display: inline;
}
</style>
