<script setup>
import { ref } from "vue";

const isClosedDesktop = ref(false)
const isOpenMobile = ref(false)

function toggleDesktop() {
  isClosedDesktop.value = !isClosedDesktop.value;
}
function toggleMobile() {
  isOpenMobile.value = !isOpenMobile.value;
}

defineExpose({ isClosedDesktop });
</script>
<template>
  <div class="w-[56px] md:w-[256px] sidebar fixed left-0 bottom-0 bg-blue-1000 border-r border-gray-300" :class="{
    'closedDesktop': isClosedDesktop,
    'openMobile': isOpenMobile
  }"
       style="background-image:radial-gradient(circle at top left, rgba(255, 255, 255, 0.2),rgba(255,255,255,0 ) 90%);">
    <ul class="pl-1 pt-2 list-none mb-[48px]">
      <slot />
    </ul>
    <div class="h-[48px] fixed bottom-0 px-[16px] hidden md:block text-white" @click="toggleDesktop">
      <div v-if="!isClosedDesktop">
        <font-awesome-icon class="mr-2" icon="fa-solid fa-angles-left"></font-awesome-icon>
        <span>Verbergen</span>
      </div>
      <font-awesome-icon v-else class="mr-2" icon="fa-solid fa-angles-right"></font-awesome-icon>
    </div>
    <div class="h-[48px] fixed bottom-0 px-[16px] block md:hidden text-white" @click="toggleMobile">
      <div v-if="isOpenMobile">
        <font-awesome-icon class="mr-2" icon="fa-solid fa-angles-left"></font-awesome-icon>
        <span>Verbergen</span>
      </div>
      <font-awesome-icon v-else class="mr-2" icon="fa-solid fa-angles-right"></font-awesome-icon>
    </div>
  </div>
</template>
<style lang="css">
.sidebar {
  top: var(--menu-height, 72px);
}

@media (max-width: 768px) {
  .openMobile {
    width: 256px !important;
  }
}

@media (min-width: 768px) {
  .closedDesktop {
    width: 56px !important;
  }
}
</style>
