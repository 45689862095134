<script setup lang="ts">
import { ref, computed } from "vue";
import ReviewerLayout from "@/Layouts/ReviewerLayout.vue";
import Card from "@/Components/Card.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import ListWithSearch from "../../Components/ListWithSearch.vue";

type Status = {
  id: number;
  current_status: string;
  subject: { name: string; id: number; profile_photo_url: string };
  team_reviewer: { user: { name: string; id: number } };
  review: { slug: string; name: string };
  has_job_profile: boolean;
};

const props = defineProps<{ reviews: Status[] }>();

const title = ref("Beoordelen");

const showAll = ref(false);

const searchFunction = (item: Status, search: string) => {
  return item.subject.name.toLowerCase().includes(search.toLowerCase());
};
const list = ref(null);
const searchReviews = computed(() => {
  if (list.value !== null) {
    return list.value.searchList;
  } else {
    return props.reviews;
  }
});
</script>
<template>
  <ReviewerLayout :title="title">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">{{ title }}</h2>
      <p class="mb-6">
        Hieronder vind je een overzicht van mensen die jij mag beoordelen. Bij ieder
        individu beoordeel je de onderdelen prestatie, resultaat en inzet.
      </p>
    </template>

    <ListWithSearch
      ref="list"
      :list="reviews"
      :searchFunction="searchFunction"
      searchLabel="Zoeken"
      searchPlaceholder="Zoek op naam"
    >
      <template
        v-for="(
          { id, current_status, subject, team_reviewer, review, slug, has_job_profile },
          key
        ) in searchReviews"
        :key="key"
      >
        <Card class="w-full flex flex-col sm:w-5/12 gap-3 justify-between items-center">
          <template v-if="has_job_profile">
            <p class="w-full text-center text-sm">Beoordeel op {{ review.name }}</p>
            <img
              class="w-18 h-18 rounded-full m-2 mx-auto"
              :src="subject.profile_photo_url"
              :alt="subject.name"
            />
            <h2 class="text-md font-bold mb-2 pl-1">{{ subject.name }}</h2>
            <PrimaryButton
              type="link"
              :href="route('reviews.show', id)"
              :alt="`${subject.name} op zijn ${review.name}`"
            >
              Beoordeel
            </PrimaryButton>
          </template>
          <template v-else>
            <p class="w-full text-center text-sm">
              Kan niet beoordelen op {{ review.name }}. Er moet eerst een functieprofiel
              gekoppeld zijn.
            </p>
            <img
              class="w-18 h-18 rounded-full m-2 mx-auto"
              :src="subject.profile_photo_url"
              :alt="subject.name"
            />
            <h2 class="text-md font-bold mb-2 pl-1">{{ subject.name }}</h2>
            <PrimaryButton
              type="button"
              :alt="`${subject.name} op zijn ${review.name}`"
              disabled="true"
            >
              Beoordeel
            </PrimaryButton>
          </template>
        </Card>
      </template>
      <template #empty> Er is niemand om te beoordelen </template>
      <template #zeroResults>
        <span>Niemand gevonden</span>
      </template>
      <template #showAllButton> laat iedereen zien </template>
    </ListWithSearch>
  </ReviewerLayout>
</template>
<style lang="css">
.cards {
  display: none;
}

.cards:nth-child(-n + 6) {
  display: flex;
}
</style>
