<script setup lang="ts">
import { computed } from 'vue';
const props = defineProps<{
    section: {
        instruction_title: string;
        instruction_body: string;
        name: string
    };
}>();

const img = computed(() => {
    return '/' + props.section.name.toLocaleLowerCase() + '.png';
});
</script>
<template>
            <div class="m-3">
                            <img :src="img" class="h-18 w-18 mx-auto my-6"/>
                <h3 class="text-lg font-medium mb-6">
                        Je gaat het onderdeel {{ section.instruction_title.toLocaleLowerCase() }} invullen.
                </h3>
                <p class="mb-3">
                    {{ section.instruction_body }}
                </p>
                <slot />
            </div>
</template>