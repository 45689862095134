<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import ReviewerLayout from '@/Layouts/ReviewerLayout.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import ReviewSlider from '@/Components/ReviewSlider.vue';
import HelpItem from '../../Components/HelpItem.vue';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import TextArea from '../../Components/TextArea.vue';
import Example from './Partials/Example.vue';
import Bluebox from '../../Components/BlueBox.vue';
import ReviewQuestion from './Partials/ReviewQuestion.vue';

type QuestionId = number;
type Value = number;

interface Question {
    id: QuestionId
    body: string
    help: string
    title?: string
}
interface Section {
    name: string,
    questions: Question[],
    question_type: string,
    instruction_title: string,
    answer_values: number[],
    answer_titles: string[],
}

const props = defineProps<{ id: number, subject: string, review: { name: string, sections: Section[] }, note: string }>()

const form = useForm(`review:${props.subject}`, {
    answers: null,
    currentSectionIndex: 0,
})

const submit = () => {
    form.transform(data => ({
        ...data,
        answers: Object.fromEntries(answers.value),
        note: note.value,
        currentSectionIndex: currentSectionIndex.value
    })).post(route('reviews.store', props.id), {
        onFinish: () => form.reset('answers'),
        onSuccess: () => {
            router.reload({ only: ['questionIndex'] })
        }
    })
}

const answers = ref(new Map<QuestionId, Value>());
const currentSectionIndex = ref<number>(0);

const rememberAnswer = router.restore(`review:${props.subject}`)
if (rememberAnswer.data.hasOwnProperty('answers') && rememberAnswer.data.answers !== null) {
    answers.value = new Map(rememberAnswer.data.answers);

    console.log(router.restore(`review:section:${props.subject}`))
    currentSectionIndex.value = router.restore(`review:section:${props.subject}`);
} else {
    const beforemount = onBeforeMount(() => {
        props.review.sections.forEach(section => {
            const middleOfValues = Math.floor(section.answer_values.length / 2);
            const middleValue = section.answer_values[middleOfValues];
            let value: number;

            if (Array.isArray(middleValue)) {
                // Slider: add up  array of middleValues and divide by length
                value = Math.round(middleValue.reduce((a, b) => a + b, 0) / middleValue.length);
            } else {
                value = middleValue;
            }
            section.questions.forEach(question => {
                answers.value.set(question.id, value);
            })
        })
    })
}


const updateAnswer = (id: QuestionId, value: string) => {
    answers.value.set(id, parseInt(value));
    router.remember([...answers.value.keys()].map((key) => [key, answers.value.get(key)]), `review:${props.subject}`);
};

const updateTotalAnswer = (section: Section, sectionIndex: number, value: string) => {
    const change = Number.parseInt(value) > medianAnswer.value[sectionIndex] ? 10 : -10;
    const answerMap = new Map(answers.value);
    // -1 or 1;
    section.questions.forEach(question => {
        // maximum of 100 and minimum of 0
        const answer = Math.min(100, Math.max(0, answerMap.get(question.id) + change));
        answerMap.set(question.id, answer);
    })
    answers.value = new Map(answerMap);
};

const sections = computed(() => [{ name: 'uitleg' }, ...props.review.sections, { name: 'samenvatting', link: '' }]);
const currentSection = computed(() => sections.value[currentSectionIndex.value]);
const currentSections = computed(() => {
    if (currentSection.value.name === 'samenvatting') {
        // only question sections.
        return props.review.sections;
    } else {
        return [sections.value[currentSectionIndex.value]]
    }
});

const isAnswerTypeRange = (section: Section) => section.hasOwnProperty('answer_values') && Array.isArray(section.answer_values[0]);
const isAnswerTypeRadio = (section: Section) => !isAnswerTypeRange(section) && !!section.answer_values;
const answerTypeRange = computed(() => isAnswerTypeRange(currentSection.value));
const answerTypeRadio = computed(() => isAnswerTypeRadio(currentSection.value));
const onTypeRangeAndNotSamenvatting = (section) => currentSection.value.name !== 'samenvatting' && isAnswerTypeRange(section) && section.name !== 'potentieel vervolg';
// if range calculate median of all answers in section
const medianAnswer = computed<Array<number>>(() => {
    const answerMap = new Map(answers.value);
    const sectionsArray = [...currentSections.value];
    return currentSections.value.map(section => {
        if (isAnswerTypeRange(section)) {
            const accumulated_answers = section.questions.reduce((accumulator, question) => {
                return accumulator + answers.value.get(question.id);
            }, 0)
            return Math.max(1, Math.round(accumulated_answers / section.questions.length));
        } if (isAnswerTypeRadio(section)) {
            const accumulated_answers = section.questions.map((question) => {
                return answers.value.get(question.id);
            })
            // get a list of occurences
            const occurences = accumulated_answers.reduce((a, b) => {
                a[b] = (a[b] || 0) + 1;
                return a;
            }, {});
            // get the two most occuring answers
            const mostOccuring = Object.keys(occurences).sort((a, b) => occurences[b] - occurences[a]).slice(0, 2);

            // get the second most common accumulated_answer

            return mostOccuring.map(a => section.answer_titles[section.answer_values.indexOf(parseInt(a))]);
        } else {
            return -1;
        }
    });
});


const name = ref(props.review.name);

const updateSectionIndex = (index: number) => {
    currentSectionIndex.value = index;
    window.scrollTo(0, 0);
    router.remember(currentSectionIndex.value, `review:section:${props.subject}`);
}

const isCollapsable = computed(() => {
    if (currentSection.value.name === 'samenvatting') {
        return true;
    }
    return null;
})

const isCollapsed = (section: Section) => {
    return true;
}

const note = ref(props.note);
const noteClosed = ref(true);

const testSliderSection = props.review.sections.filter(section => isAnswerTypeRange(section))[0];
const testChoiceAnswerSection = props.review.sections.filter(section => isAnswerTypeRadio(section))[0]
</script>
<template>
    <ReviewerLayout :sections="sections" :sectionIndex="currentSectionIndex">

        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                {{ name }}
            </h2>
        </template>

        <div>
            <div>
                <div v-if="currentSection.name !== 'uitleg'" class="float-right cursor-pointer text-gray-600"
                     @click="noteClosed = !noteClosed">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square"
                                       class="mx-1 w-6 h-6 text-blue-800" />
                    Aantekeningen maken
                </div>
                <h3 v-if="currentSection.name === 'samenvatting'" class="font-semibold text-xl">
                    {{ currentSection.name }}
                </h3>
                <p v-if="currentSection.name === 'samenvatting'">
                    Hieronder staat uw beoordeling van {{ subject }} opgesomd:
                </p>
                <div v-if="currentSection.name === 'uitleg'">
                    <Example
                             :subject="subject"
                             :testSliderValues=testSliderSection.answer_values
                             :testSliderTitles=testSliderSection.answer_titles
                             :testChoiceAnswerValues=testChoiceAnswerSection.answer_values
                             :testChoiceAnswerTitles=testChoiceAnswerSection.answer_titles />
                </div>

                <div v-else>

                    <template v-for="section, index in currentSections">
                        <input v-if="isCollapsable" :id="section.name" class="toggle hidden" type="checkbox"
                               :checked="isCollapsed(section)" />
                        <label :for="section.name" class="block label-toggle">
                            <h3 class="inline-block font-semibold text-lg pt-9 cursor-pointer">{{ section.instruction_title
                            }}</h3>
                            <div v-if="currentSection.name === 'potentieel'">
                                <p>
                                    Geef hieronder aan in welke mate {{ subject }} de volgende competenties laat zien:
                                </p>
                            </div>
                            <div v-if="currentSection.name === 'potentieel vervolg'">
                                <p>
                                    Geef hieronder aan in welke mate {{ subject }} de juiste kennis, ervaring en
                                    vaardigheden in huis heeft om de functie uit te kunnen voeren:
                                </p>
                            </div>
                            <div v-if="currentSection.name === 'resultaat'">
                                <p>
                                    Geef hieronder aan in welke mate {{ subject }} kan voldoen aan de volgende gedragingen:
                                </p>
                            </div>
                            <div v-if="currentSection.name === 'inzet'">
                                <p>
                                    Geef hieronder aan in welke mate {{ subject }} beoogde resultaten behaald:
                                </p>
                            </div>
                            <Bluebox
                                     v-if="isAnswerTypeRange(section) && medianAnswer[index] > 0 && section.name !== 'potentieel vervolg'">
                                <p class="w-full md:w-1/2 shrink-0 grow-0 mb-3 font-medium">Algemene beoordeling: </p>
                                <div class="ml-6">
                                    <ReviewSlider
                                                  :values="section.answer_values"
                                                  :titles="section.answer_titles"
                                                  :step="10"
                                                  :answer="medianAnswer[index]"
                                                  @update:answer="updateTotalAnswer(section, index, $event)" />
                                </div>
                            </Bluebox>
                            <Bluebox v-if="isAnswerTypeRadio(section) && medianAnswer[index].length > 0">
                                <p v-if="medianAnswer[index].length === 1">Alle vragen over inzet zijn beantwoord met <span
                                          class="italic">{{
                                              medianAnswer[index][0] }}</span>.</p>
                                <p v-else>De meeste vragen over inzet zijn beantwoord met <span class="italic">{{
                                    medianAnswer[index][0] }}</span> en <span class="italic">{{
        medianAnswer[index][1] }}</span>.
                                </p>
                            </Bluebox>
                        </label>
                        <div class="collapsable overflow-hidden">
                            <template v-for="question, index in section.questions">
                                <ReviewQuestion :index="index" :body="question.body" :help="question.help"
                                                :title="question.title">
                                    <div v-if="isAnswerTypeRange(section)" class="flex gap-2 sm:ml-6">
                                        <ReviewSlider :values="section.answer_values"
                                                      :titles="section.answer_titles"
                                                      :answer="answers.get(question.id)"
                                                      @update:answer="updateAnswer(question.id, $event)" />
                                    </div>
                                    <div class="flex gap-3" v-else>
                                        <template v-for="(value, answer_index) in section.answer_values">
                                            <label class="inline-block text-sm text-gray-700 text-center w-[40em]"
                                                   :for="`${question.id}-${value}`"><span
                                                      class="block" :class="{
                                                          'sm:hidden': index % 3 !== 0,
                                                      }">{{ section.answer_titles[answer_index] }}</span>
                                                <input class="" type="radio" :id="`${question.id}-${value}`"
                                                       :value="value"
                                                       :checked="answers.get(question.id) === value"
                                                       @change="updateAnswer(question.id, value)">
                                            </label>
                                        </template>
                                    </div>
                                </ReviewQuestion>
                                <p v-if="section.questions[section.questions.length -1] === question" class="pt-12 px-3 text-gray-700 text-sm italic">
                                    n.v.t.: weet ik niet, kan ik niet beoordelen, is niet relevant voor de functie
                                </p>
                            </template>
                        </div>
                        <Bluebox v-if="onTypeRangeAndNotSamenvatting(section) && medianAnswer[index] > 0">
                            <p class="w-full md:w-1/2 shrink-0 grow-0 mb-3 font-medium">Algemene beoordeling: </p>
                            <div class="ml-6">
                                <ReviewSlider
                                              :values="section.answer_values"
                                              :titles="section.answer_titles"
                                              :step="10"
                                              :answer="medianAnswer[index]"
                                              @update:answer="updateTotalAnswer(section, index, $event)" />
                            </div>
                        </Bluebox>
                    </template>
                </div>
            </div>
        </div>
        <!-- notities -->
        <div class="fixed bottom-0 left-0 right-0 max-w-2xl ml-6 mr-48 xl:mr-4 p-3 border rounded-t-md shadow-xl bg-white">
            <input id="notes" class="toggle hidden" type="checkbox"
                   :checked="noteClosed" />
            <label for="notes" class="block cursor-pointer label-toggle">
                <h3 class="font-medium mb-2 inline-block">Notities
                    <font-awesome-icon icon="fa-solid fa-pen-to-square"
                                       class="ml-1 w-4 h-4 text-blue-800" />
                </h3>
            </label>
            <TextArea v-model="note" label="Notities" class="collapsable w-full" />
        </div>
        <template #next>
            <PrimaryButton v-if="currentSection.name === 'samenvatting'" @click="submit"
                           class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Bevestigen
            </PrimaryButton>
            <PrimaryButton v-else @click="updateSectionIndex(currentSectionIndex + 1)"
                           class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Volgende
            </PrimaryButton>
        </template>


    </ReviewerLayout>
</template>

<style lang="css">
.toggle+label::before {
    content: ' ';
    display: inline-block;

    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;

    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);

    transition: transform .2s ease-out;

}

.toggle:checked+label+.collapsable {
    max-height: 0px !important;
    transition: max-height .25s ease-in-out;
}

.toggle:checked+label+textarea.collapsable {
    display: none;
}

.toggle:checked+label::before {
    transform: rotate(0deg) translateY(-2px);
}

.toggle+label::before {
    transform: rotate(90deg) translateY(-3px);
}
</style>