<script lang="ts" setup>
import { onMounted, ref, computed, watch } from 'vue';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Item {
    name: string
    performance: number
    potential: number
    color: string
    checked?: boolean
    category?: string
}
const props = defineProps<{
    data: Array<Item>;
    categories: object
}>();

const data = ref(props.data.map((item, index) => {
    return {
        ...item,
        checked: true,
        number: index + 1
    }
}));

// function to split the data into two arrays based on a condition
function partition(array, callback) {
    return array.reduce(function (result, element, i) {
        callback(element, i, array)
            ? result[0].value.push(element)
            : result[1].value.push(element);
        return result;
    }, [ref([]), ref([])]
    );
};

const [assessmentAndReviewDone, assessmentAndReviewNotFinished] = partition(data.value, item => item.performance && item.potential);

// to fit the scores in the right boxes. the score needs to be scaled to:
// 0 - 40 => 0 - 33; 40 - 70 => 33 - 66; 70 - 100 => 66 - 100
const rescale = (value) => {
    if (value < 40) {
        return value * 0.825 // 0.825 = 33 / 40
    } else if (value < 70) {
        return (value - 40) * 1.1 + 33 // 1.1 = 33 / 30
    } else {
        return (value - 70) * 1.1 + 66 // 1.1 = 33 / 30
    }
}

const datasets = computed(() => {
    return data.value.filter(item => item.checked)
        .map(({ name, number, performance, potential, color, category }) => {
            // let color = `rgba(${potential * 2.55}, ${performance * 2.55}, 0, 0.5)`
            return {
                label: name,
                data: [{
                    x: rescale(performance),
                    y: rescale(potential),
                    label: number,
                    category: category,
                    potential: potential,
                    performance: performance,
                    name: name,
                    number: number
                }],
                backgroundColor: color,
                pointRadius: 10,
                pointHoverRadius: 10,
                pointHitRadius: 10,
                pointBorderWidth: 2,
                pointStyle: 'circle',
                hoverBackgroundColor: color,
                hoverBorderColor: color,
                hoverBorderWidth: 2,
                hoverRadius: 10,
                showLine: false,
            }
        })
})

const onlyShow0and100 = (value) => value === 0 || value === 100 ? value : ''
const options = {
    animation: false,
    // remove legend
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: '#fff',
            titleColor: '#000',
            bodyColor: '#000',
            borderColor: '#999',
            callbacks: {
                label: function (context) {
                    return (`${context.raw.number}. ${context.dataset.label || ''} (${context.raw.performance},${context.raw.potential})`)
                }
            }
        },
        datalabels: {
            align: 'left',
            anchor: 'end',
            offset: 10,
            color: '#000',
            font: {
                weight: 'bold'
            }
        }
    },
    scales: {
        y: {
            // show yAxis label
            title: {
                display: true,
                text: 'Potentie',
            },
            min: 0,
            max: 100,
            ticks: {
                callback: onlyShow0and100,
                section: 3,
                stepSize: 33.33,
                padding: 20,
            },
            grid: {
                tickBorderDash: [5, 5],
            }
        },
        x: {
            title: {
                display: true,
                text: 'Prestatie',
            },
            min: 0,
            max: 100,
            ticks: {
                callback: onlyShow0and100,
                stepSize: 33.33,
                padding: 10,
            },
            grid: {
                drawOnChartArea: true,
                tickBorderDash: [5, 5],
            }
        }
    }
}

const canvas = ref(null);
const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})

let chart = ref(null);

const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxGap = canvas.value.getContext('2d');
    chart.value = new Chart(ctxGap, {
        plugins: [ChartDataLabels],
        type: 'scatter',
        options: options,
        data: {
            datasets: datasets.value
        }
    })
}

watch(datasets, () => {
    renderChart();
})

function Status({ checked, performance, potential }) {
    if (performance && potential) {

        if (checked) {
            return ['fas', 'face-smile']
        } else {
            return ['far', 'face-smile']
        }
    } else {
        return ['far', 'circle-question']
    }
}
const gridCategories = Object.keys(props.categories).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
}, {})
// calculate the amount of people in each category
const categoryAmounts = computed(() => {
    return data.value.reduce((acc, item) => {
        acc[item.category]++
        return acc
    }, gridCategories
    )
})
const someoneScored = computed(() => {
    return data.value.some(item => item.performance && item.potential)
})
const someoneHasReview = computed(() => {
    return data.value.some(item => item.performance)
})
const someoneHasAssessment = computed(() => {
    return data.value.some(item => item.potential && !item.performance)
})
const someoneInTeam = computed(() => {
    return data.value.length > 0
})

const listCollapsed = ref(true);

</script>
<template>
    <div class="flex flex-wrap w-full p-6">
        <div class="w-full grow-0 lg:w-3/4">
            <canvas v-if="someoneScored" ref="canvas" id="gap-score-graph" width="400" height="200"></canvas>
            <div v-else class="flex flex-col items-center justify-center h-40 w-full bg-gray-100">
                <p class="text-gray-500 font-bold">Er is niet genoeg informatie beschikbaar.</p>
                <p v-if="!someoneInTeam" class="text-gray-500 text-sm">Er zijn nog geen teamleden toegevoegd.</p>
                <p v-if="!someoneHasAssessment" class="text-gray-500 text-sm">Er zijn nog geen ontwikkelassessments gemaakt.
                </p>
                <p v-if="!someoneHasReview" class="text-gray-500 text-sm">Er zijn nog geen teamleden beoordeeld.</p>
            </div>
        </div>
        <div class="w-full lg:w-1/4 lg:pl-8 flex gap-6 flex-wrap">
            <h3 class="font-semibold text-lg ml-6 w-full">{{ data.length }} Teamleden</h3>
            <div v-for="users, index in [assessmentAndReviewDone, assessmentAndReviewNotFinished]" :key="index"
                 class="lg:w-full flex-grow">
                <div v-for="item, index in  users " :key="item.name" class="mb-1 border-b border-gray-100"
                     :class="{ 'hidden': index > 4 && listCollapsed }">
                    <label :for="index + '_select'" class="flex items-center">
                        <input :id="index + '_select'" type="checkbox" v-model="item.checked" class="hidden">
                        <font-awesome-icon :icon="Status(item)" class="mr-2 h-5 text-blue-800"
                                           :style="{ color: item.color }" />
                        <span class="w-6">
                            {{ item.number }}
                        </span>
                        {{ item.name }}
                        <!-- // checkbox for each item to toggle visibility -->
                    </label>
                </div>
                <button v-if="assessmentAndReviewDone.length > 5 || assessmentAndReviewNotFinished.length > 5"
                        class="hidden lg:block w-full text-blue-800"
                        @click="listCollapsed = !listCollapsed">
                    <span v-if="listCollapsed">Toon alles</span>
                    <span v-else>Verberg</span>
                </button>
            </div>
            <button v-if="assessmentAndReviewDone.length > 5 || assessmentAndReviewNotFinished.length > 5"
                    class="w-full text-blue-800 lg:hidden"
                    @click="listCollapsed = !listCollapsed">
                <span v-if="listCollapsed">Toon alles</span>
                <span v-else>Verberg</span>
            </button>
        </div>
    </div>
    <div class="p-6 hidden">
        <div v-for="amount, category in categoryAmounts" :key="category" class="inline-block h-20 mr-4">
            <span class="p-3 pt-4 rounded-l-lg border border-r-0 border-gray-100" :style="{ backgroundColor: category }">
                <font-awesome-icon :icon="['fas', 'circle-user']" class="mr-2 h-5 bg-gray-400 rounded-full"
                                   :style="{ color: categories[category] }" />
                {{ amount }}
            </span>
            <span class="p-3 pt-4 rounded-r-lg border border-blue-100 bg-blue-100">
                {{ category }}
            </span>
        </div>
    </div>
</template>