<script setup lang="ts">
import { defineProps, ref, computed } from 'vue';
import ManagerLayout from '../../Layouts/ManagerLayout.vue';
import Card from '../../Components/Card.vue';
import PrimaryButton from '../../Components/PrimaryButton.vue';
import ListWithSearch from '../../Components/ListWithSearch.vue';


interface User {
  name: string
  assessment_status: []
  id: number
  profile_photo_url: string
  scores_exists: boolean
}
interface Team {
    name: string
    members: Array<User>
    id: number
    company_name: string
}
const props = defineProps<{
    team: Team,
    permissions: Object,
}>();

const title = ref('Beoordelen');

const showAll = ref(false);
const users = ref(props.team.members
    .filter((User: User) => User.scores_exists )
    .map((user: User) => { return { ...user, searchList: true }}))

const searchFunction = (item: User, search: string) => {
    return item.name.toLowerCase().includes(search.toLowerCase());
}

const list = ref(null);
const searchUsers = computed(() => {
    if (list.value !== null) {
        return list.value.searchList;
    } else {
        return users.value;
    }
});
</script>
<template>
  <ManagerLayout title="Team persoonsoverzichten">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">
    Team - {{ team.name }}
      </h2>
    </template>
    <div class="bg-white p-2 border rounded shadow-sm">
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">
        <ListWithSearch ref="list" :list="users" :searchFunction="searchFunction" searchLabel="Zoeken"
                        searchPlaceholder="Zoek op naam">
            <template v-for="({ name, assessment_status, id, profile_photo_url }, key) in searchUsers"
                      :key="key">
                <Card
                      class="w-full flex flex-col sm:w-5/12 lg:w-1/5 gap-3 justify-between items-center">
                    <img class="w-18 h-18 rounded-full m-2 mx-auto" :src="profile_photo_url"
                         :alt="name">
                    <h2 class="text-md font-bold mb-2 pl-1">{{ name }}</h2>
                    <PrimaryButton class="text-xs" type="link" :href="route('results.show.review', { id: id, })"
                                   :alt="`${name} persoonsoverzicht`">
                                   Persoonsoverzicht
                    </PrimaryButton>
                    <a class="text-gray-600 text-xs underline" :href="route('results.show.review', { id: id}) + '?anon=1'"
                                   :alt="`${name} persoonsoverzicht`">
                                   anoniem persoonsoverzicht
                    </a>
                </Card>
            </template>
            <template #empty>
              Er zijn geen teamleden
            </template>
            <template #zeroResults>
                <span>Niemand gevonden</span>
            </template>
            <template #showAllButton>
                laat iedereen zien
            </template>
        </ListWithSearch>
            </div>
            </div>
  </ManagerLayout>
</template>
