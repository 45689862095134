<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import ActionSection from '@/Components/ActionSection.vue';
import DangerButton from '@/Components/DangerButton.vue';
import DialogModal from '@/Components/DialogModal.vue';
import InputError from '@/Components/InputError.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import TextInput from '@/Components/TextInput.vue';

const confirmingUserDeletion = ref(false);
const passwordInput = ref(null);

const form = useForm({
    password: '',
});

const confirmUserDeletion = () => {
    confirmingUserDeletion.value = true;

    setTimeout(() => passwordInput.value.focus(), 250);
};

const deleteUser = () => {
    form.delete(route('current-user.destroy'), {
        preserveScroll: true,
        onSuccess: () => closeModal(),
        onError: () => passwordInput.value.focus(),
        onFinish: () => form.reset(),
    });
};

const closeModal = () => {
    confirmingUserDeletion.value = false;

    form.reset();
};
</script>

<template>
    <ActionSection>
        <template #title>
            Verwijder Account
        </template>

        <template #description>
            Permanent verwijderen van account.
        </template>

        <template #content>
            <div class="max-w-xl text-sm text-gray-600">
                Wanneer je account verwijderd is, dan worden alle informatie en data verwijderd.
            </div>

            <div class="mt-5">
                <DangerButton @click="confirmUserDeletion">
                    Verwijder Account
                </DangerButton>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <DialogModal :show="confirmingUserDeletion" @close="closeModal">
                <template #title>
                    Verwijder Account
                </template>

                <template #content>
                    Weet je zeker dat je het account wilt verwijderen?

                    <div class="mt-4">
                        <TextInput
                                   ref="passwordInput"
                                   v-model="form.password"
                                   type="password"
                                   class="mt-1 block w-3/4"
                                   placeholder="Password"
                                   @keyup.enter="deleteUser" />

                        <InputError :message="form.errors.password" class="mt-2" />
                    </div>
                </template>

                <template #footer>
                    <SecondaryButton @click="closeModal">
                        Annuleer
                    </SecondaryButton>

                    <DangerButton
                                  class="ml-3"
                                  :class="{ 'opacity-25': form.processing }"
                                  :disabled="form.processing"
                                  @click="deleteUser">
                        Verwijder Account
                    </DangerButton>
                </template>
            </DialogModal>
        </template>
    </ActionSection>
</template>
