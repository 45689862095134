<script setup lang="ts">
import { computed, withDefaults } from 'vue'
import ReviewSmiles from './ReviewSmiles.vue';

type Min = number;
type Max = number;

const props = withDefaults(defineProps<{ answer: number, values: [Min, Max], titles: Array<string>, step?: number }>(), {
    step: () => 1
})

const emits = defineEmits<{ (e: 'update:answer', answer: number): void }>()

const range = computed<[Min, Max]>(() => {
    // merge values arrays into one array
    const arr = props.values.flat();
    // and get min and max
    return [Math.min(...arr), Math.max(...arr)];
})

const answer = computed<number>({
    get() {
        return props.answer
    },
    set(value) {
        emits('update:answer', value)
    }
})
const answerIndex = computed<number>(() => {
    return props.values.findIndex(([min, max]) => min <= props.answer && max >= props.answer);
})

const title = computed(() => {
    return props.titles[answerIndex.value];
})

</script>
<template>
    <div class="sm:flex sm:gap-16 w-full">
        <label class="slider-label md:ml-20 my-[8px] sm:order-2 text-sm rounded bg-blue-500 text-white uppercase p-2">{{
            title
        }}</label>
        <div
             class="slider-container cursor-pointer flex items-center h-[6px] px-[2%] rounded-[20px] relative w-[320px] my-[15px] ">

            <div class="pdc-tooltip absolute transform -translate-x-1/2 "
                 :style="{ left: `${2 + answer * 0.96}%`, top: '-9' }">
                <span class="tooltiptext">
                    <ReviewSmiles :title="title" class="w-[35px] h-[35px]" />
                </span>
            </div>
            <input class="absolute cursor-pointer left-0 right-0" type="range" :step="step" v-model="answer" :min="range[0]"
                   :max="range[1]">
        </div>
    </div>
</template>
<style scoped>
.slider-container {
    background: linear-gradient(to right,
            #EA2931 10%, orange 47%, #F4E051 62%, #B7E869 75%, green 90%);
}

.slider-container [type=range] {
    -webkit-appearance: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    margin: 0;
    padding: 0;
}

.slider-container [type=range]::-moz-range-thumb,
.slider-container [type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    opacity: 0;
    border-radius: 50%;
}
</style>