<script setup>
import { computed } from 'vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';

const props = defineProps({
    status: String,
});

const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('verification.send'));
};

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');
</script>

<template>
    <Head title="Email Verification" />

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>

        <div class="mb-4 text-sm text-gray-600">
            Voordat u verder gaat, zou u het e-mailadres willen verifiëren door op de link te klikken die we zojuist hebben
            gemaild? Als u de e-mail niet hebt ontvangen, klikt u hieronder om een nieuwe te ontvangen.
        </div>

        <div v-if="verificationLinkSent" class="mb-4 font-medium text-sm text-green-600">
            Een nieuwe verificatielink is naar het e-mailadres dat u heeft opgegeven in uw profielinstellingen verzonden.
        </div>

        <form @submit.prevent="submit">
            <div class="mt-4 flex items-center justify-between">
                <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Verstuur Verificatiemail
                </PrimaryButton>

                <div>
                    <Link
                          :href="route('profile.show')"
                          class="underline text-sm text-gray-600 hover:text-gray-900">
                    Profiel aanpassen</Link>

                    <Link
                          :href="route('logout')"
                          method="post"
                          as="button"
                          class="underline text-sm text-gray-600 hover:text-gray-900 ml-2">
                    Uitloggen
                    </Link>
                </div>
            </div>
        </form>
    </AuthenticationCard>
</template>
