<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Item {
    id: number
    name: string
    behaviour: object
}

const props = defineProps<{
    data: Array<Item>,
    colors: object
}>();

const datasets = computed(() => {
    return [{
        data: props.data,
        backgroundColor: [...Object.values(props.colors), '#EEE'],
    }]
})

const options = {
    plugins: {
        legend: {
            display: false,
        },
        datalabels: {
            display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
            },
            color: '#000',
            font: {
                size: 16,
                weight: 'bold'
            },
        }
    }

}


const canvas = ref(null);
const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})

let chart = ref(null);
const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxAmounts = canvas.value.getContext('2d');
    chart.value = new Chart(ctxAmounts, {
        type: 'pie',
        plugins: [ChartDataLabels],
        labels: ['Rood', 'Geel', 'Groen', 'Blauw', 'Niet ingevuld'],
        options: options,
        data: {
            datasets: datasets.value
        }
    })
}
</script>

<template>
    <canvas ref="canvas" id="behaviour-amounts-chart" width="400" height="400"></canvas>
</template>
