<script setup lang="ts">
import { computed, defineEmits, onMounted } from 'vue';
import useGradients from '../composables/gradients';

const { linearGradient } = useGradients();

const props = withDefaults(defineProps<{ id: number, questionTitle: string, questionBody, options?: Array<string>, answer?: string | number, showLabels: boolean, questionNumber: number, autofocus?: boolean }>(),
    { autofocus: () => false, showLabels: () => true, answer: () => null, options: () => null })

const emit = defineEmits(['update:answer'])

const selectAnswer = computed({
    get: () => { return props.answer },
    set: (value) => emit('update:answer', value)
})

</script>
<template>
    <div class="w-full sm:flex bg-gray-100 text-black rounded-lg overflow-clip">
        <div class="px-6 py-3 w-full sm:w-1/2 bg-white flex justify-items-center">

            <p class="my-auto -indent-8 pl-8">
                <span class="mr-1">{{ questionNumber.toString() }}.</span> {{ questionBody }}
            </p>
        </div>
        <div class="w-full sm:w-1/2 py-3 justify-end flex flex-row items-end ml-auto sm:ml-0 px-4">
            <div class="w-full flex flex-row flex-wrap justify-between items-end sm:justify-start sm:gap-2">
                <p class="w-full -indent-8 pl-8">
                    {{ questionTitle }}
                </p>
                <select :name="id.toString()"
                        class="rounded-lg border-0 bg-blue-150"
                        v-model="selectAnswer">
                    <template v-for="option in options">
                        <option :value="option.answer_body">{{ option.answer_title }}</option>
                    </template>
                </select>
            </div>
        </div>
    </div>
</template>
