type AssessmentType = "competentie" | "drijfveren" | "drijfveren_intro" | "drijfveren_col" | "gedrag" ;

const gradients = {
    competenties: "#65C090, #244835",
    competentie_bg: "#65C090, #244835",
    competenties_bg: "#65C090, #244835",
    drijfveren: "#FFF, #FFF",
    drijfveren_intro: "#8FB0D2, #36495D",
    drijfveren_col: "#8FB0D2, #36495D",
    gedrag: "#C38EBA, #6C3C64",
};


function isAssessmentType(color: string): color is AssessmentType {
    return color in gradients;
}

function linearGradient(direction: string, color?: AssessmentType | string) {
    color = color.toLocaleLowerCase();
    if(isAssessmentType(color))
        return `linear-gradient(${direction}, ${gradients[color]})`;
    else if(isAssessmentType(color.split("_")[0])){
        return `linear-gradient(${direction}, ${gradients[color.split("_")[0]]})`;
    }
    else if(new RegExp(/#[a-f0-9A-F]{6-8}( +, #[a-f0-9A-F]{6-8})+/).test(color)){
        return `linear-gradient(${direction}, ${color})`;
    } else {
        return `linear-gradient(${direction}, #8FC2D2, #36565D)`;
    }
}

function radialGradient(color: AssessmentType | string) {
    if(isAssessmentType(color))
        return `radial-gradient(${gradients[color]})`;
    else {
        return `radial-gradient(${color})`;
    }
}

export default function useGradients() {
    return {
        linearGradient,
        radialGradient,
    };
}
