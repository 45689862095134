<script lang="ts" setup>
import { ref, computed } from 'vue';
import { QuestionType } from '../../enum';
import QuestionRadioScale from '@/Components/QuestionRadioScale.vue';
import QuestionRankOrder from '@/Components/QuestionRankOrder.vue';
import QuestionSingleSelectMultipleChoice from './QuestionSingleSelectMultipleChoice.vue';
import QuestionMostLeast from './QuestionMostLeast.vue';
import useGradients from '../composables/gradients';

const isMobile = ref(window.innerWidth < 650);

const enum PageQuestionAmount {
    RadioScale = 12,
    MostLeast = 1,
    RankOrder = 1,
    SingleSelectMultipleChoice = 4,
}

type Answer = string | number | [string, string];

const props = defineProps<{
    questions: {
        id: number;
        question_body: string;
        question_title: string;
        answer_options?: string[] | { answer_title: string, id: number }[];
        type: 'likert_scale_5' | 'max_diff' | 'rank_order' | 'single_select_multiple_choice';
    }[];
    currentQuestionIndex: number,
    sectionName: string
}>();
const emit = defineEmits(['update:answers', 'finished']);


const currentQuestionType = computed(() => props.questions[props.currentQuestionIndex].type);
const currentPageQuestionAmount = computed(() => {
    switch (currentQuestionType.value) {
        case QuestionType.LikertScale5:
            return PageQuestionAmount.RadioScale;
        case QuestionType.MostLeast:
            return PageQuestionAmount.MostLeast;
        case QuestionType.RankOrder:
            return PageQuestionAmount.RankOrder;
        case QuestionType.SingleSelectMultipleChoice:
            return PageQuestionAmount.SingleSelectMultipleChoice;
        default:
            return 1;
    }
});

const currentQuestions = computed(() => props.questions.slice(props.currentQuestionIndex, props.currentQuestionIndex + currentPageQuestionAmount.value));
const currentQuestionAmount = computed(() => currentQuestions.value.length);




const answers = ref(new Map<number, Answer>());
const clicks = ref(0);
function updateAnswer(id: number, answer: Answer) {
    answers.value.set(id, answer);
    clicks.value = clicks.value + 1;
}
const answerAmount = computed(() => answers.value.size);

const open = computed(() => {
    return answerAmount.value !== currentQuestionAmount.value;
});

const isVerified = ref(false);
const needsVerification = computed(() =>
    !isVerified.value &&
    clicks.value < 2 &&
    isMobile.value &&
    props.questions[0].type === QuestionType.RankOrder);

function verify() {
    isVerified.value = true;
}

const { linearGradient } = useGradients();


defineExpose({ answers, open, needsVerification, verify, isVerified })

</script>
<template>
    <div>
        <slot name="top" />
        <template v-if="currentQuestionType === QuestionType.LikertScale5">
            <div class="md:pb-5">
                <QuestionRadioScale class="max-w-5xl lg:mx-auto border rounded-md shadow"
                                    v-for="({ id, question_title, answer_options }, index) in currentQuestions" :id="id"
                                    :question="question_title"
                                    :question-number="props.currentQuestionIndex + index + 1" :options="answer_options"
                                    :answer="answers.get(id)"
                                    :showLabels="index % 3 === 0" :class="{
                                        'mt-8': index % 3 === 0,
                                        'mt-4': index % 3 !== 0,
                                    }" @update:answer="(val) => updateAnswer(id, val)" :autofocus="index === 0" />
            </div>
        </template>
        <template v-if="currentQuestionType === QuestionType.MostLeast">
            <QuestionMostLeast v-for="({ id, answer_options }, index) in currentQuestions" :id="id" :items="answer_options"
                               :answer="answers.get(id)" @update:answer="(val) => updateAnswer(id, val)" />
        </template>
        <template v-if="currentQuestionType === QuestionType.RankOrder">
            <QuestionRankOrder v-for="({ id, question_title, answer_options }, index) in currentQuestions" :id="id"
                               :bgGradient="linearGradient('to bottom', sectionName + '_col')"
                               :question="question_title + ' '" :options="answer_options" :answer="answers.get(id)"
                               @update:answer="(val) => updateAnswer(id, val)" :singleColumn="isMobile" />
        </template>
        <template v-if="currentQuestionType === QuestionType.SingleSelectMultipleChoice">
            <QuestionSingleSelectMultipleChoice v-for="({ id, question_title, question_body, answer_options }, index) in currentQuestions"
                                                :id="id" :question-title="question_title" :question-body="question_body"
                                                :options="answer_options"
                                                :question-number="props.currentQuestionIndex + index + 1"
                                                :answer="answers.get(id)" @update:answer="(val) => updateAnswer(id, val)" />
        </template>
    </div>
</template>