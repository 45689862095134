<script setup lang="ts">
import { ref, computed } from 'vue';
import ReviewSlider from "@/Components/ReviewSlider.vue";
import BlueBox from '../../../Components/BlueBox.vue';
import ReviewQuestion from "./ReviewQuestion.vue";

const props = defineProps<{
    subject: string,
    testSliderValues: number[],
    testSliderTitles: string[],
    testChoiceAnswerValues: number[],
    testChoiceAnswerTitles: string[],
}>();

const testSliderAnswer = ref([60, 60]);
const testChoiceAnswer = ref(1);
const medianAnswer = computed(() => {
    return Math.round(testSliderAnswer.value.reduce((a, b) => a + b, 0) / testSliderAnswer.value.length);
})
const updateMedianAnswer = (value: number) => {
    const change = Number.parseInt(value) > medianAnswer.value ? 10 : -10;
    testSliderAnswer.value = testSliderAnswer.value.map((v) => Math.min(100, Math.max(0, v + change)));
}

</script>
<template>
    <h3 class="font-medium text-xl">Hoe werkt het</h3>
    <p>Hierna beoordeelt u {{ subject }} op prestatie, resultaat en inzet.
        Er volgen per onderdeel verschillende items waarbij u beoordeelt in hoeverre {{ subject }} dit
        laat zien in zijn/haar werk. </p>
    <p class="mt-3">Er komen 2 soorten vragen voor bij het beoordelen, hieronder staan interactieve voorbeelden.</p>
    <div class="ml-6 ">
        <p class="mt-6 mb-6 -ml-3 font-medium">keuzevragen</p>
        <div class="flex gap-3">
            <template v-for="(value, index) in testChoiceAnswerValues">
                <label class="inline-block text-sm text-gray-700 text-center w-[40em]"
                       :for="`${'tt'}-${value}`"><span
                          class="block">{{
                              testChoiceAnswerTitles[index]
                          }}</span>
                    <input class="inline-block" type="radio" :id="`${'tt'}-${value}`"
                           :value="value"
                           :checked="testChoiceAnswer === value"
                           @change="testChoiceAnswer = value">
                </label>
            </template>
        </div>
        <p class="pt-6 px-3 text-gray-700 text-sm italic">
            n.v.t.: weet ik niet, kan ik niet beoordelen, is niet relevant voor de functie
        </p>
        <p class="mt-12 mb-3 -ml-3 font-medium">
            schaalvragen</p>
        <p>U kunt een algemene beoordeling geven op prestatie en resultaat. Om
            vervolgens specifiek de vragen te beoordelen. Hieronder kunt u dat uitproberen.
        </p>
        <BlueBox>
            <p class="w-full md:w-1/2 shrink-0 grow-0 mb-3 font-semibold">Algemene beoordeling: </p>
            <ReviewSlider
                          :values="testSliderValues"
                          :titles="testSliderTitles"
                          :step="10"
                          :answer="medianAnswer"
                          @update:answer="updateMedianAnswer($event)" />
        </BlueBox>
        <template v-for="(answer, index) in testSliderAnswer">
            <ReviewQuestion :index="index"
                            :body="`Schuif deze vraag naar ${index == 0 ? 'beneden' : 'boven'}. De algemene beoordeling gaat ook naar ${index == 0 ? 'beneden' : 'boven'}.`">
                <ReviewSlider
                              :values="testSliderValues"
                              :titles="testSliderTitles"
                              :step="1"
                              :answer="answer"
                              @update:answer="testSliderAnswer[index] = Number.parseInt($event)" />
            </ReviewQuestion>
        </template>
        <p class="italic">U geeft uw eerste indruk. Toetst deze met de specifiek vragen. En kunt daarna nogmaals beoordelen
            of
            de algemene beoordeling juist is.
        </p>
    </div>
    <p class="mt-12 mb-3 font-semibold">
        <font-awesome-icon icon="fa-solid fa-pen-to-square"
                           class="mx-1 w-6 h-6 text-blue-800" />
        Aantekeningen maken
    </p>
    <p>linksonder is een plek voor korte notities. Deze zijn alleen voor u beschikbaar.</p>
</template>