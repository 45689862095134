<script setup>
import { computed, defineProps, ref } from "vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import Banner from "@/Components/Banner.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import TextInput from "@/Components/TextInput.vue";
import LoginLink from "@/../../vendor/spatie/laravel-login-link/resources/js/login-link.vue";

const props = defineProps({
  canResetPassword: Boolean,
  status: String,
  email: String,
});

const form = useForm(`LoginEmail`, {
  email: props.email,
});

const submit = () => {
  form.post(route("login.email"));
};
const requestedPassword = ref(false);
const submitRequestPassword = () => {
  requestedPassword.value = true;
  form.post(route("password.email"));
};

const resetSuccess = computed(() => {
  return form.recentlySuccessful && requestedPassword.value;
});
</script>

<template>
  <Head title="Log in" />

  <AuthenticationCard>
    <template #logo>
      <AuthenticationCardLogo />
    </template>
    <LoginLink
      v-if="$page.props.environment == 'local'"
      email="test+demo@vipd.nl"
      label="Login as test+demo@vipd.nl"
      class="pb-3 text-blue-800 underline"
    />
    <LoginLink
      v-if="$page.props.environment == 'local'"
      email="test+demo_manager3@vipd.nl"
      label="Login as test+demo_manager3@vipd.nl"
      class="pb-3 text-blue-800 underline"
    />
    <LoginLink
      v-if="$page.props.environment == 'local'"
      email="test+demo_1@vipd.nl"
      label="Login as test+demo_1@vipd.nl"
      class="pb-3 text-blue-800 underline"
    />
    <LoginLink
      v-if="$page.props.environment == 'local'"
      email="test+demo_11@vipd.nl"
      label="Login as test+demo_11@vipd.nl"
      class="pb-3 text-blue-800 underline"
    />

    <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
      {{ status }}
    </div>

    <form @submit.prevent="submit">
      <div class="block mt-4">
        <InputLabel for="email" value="email" />
        <TextInput
          id="email"
          v-model="form.email"
          type="email"
          class="mt-1 block w-full"
          required
          autofocus
        />
        <InputError class="mt-2" :message="form.errors.email" />
      </div>
      <p v-if="resetSuccess" class="mt-2 text-sm">
        Een inlogcode is naar je e-mailadres gestuurd.
      </p>

      <div class="flex items-center flex-col justify-end mt-12">
        <PrimaryButton
          class="mt-4 w-full"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          met wachtwoord inloggen
        </PrimaryButton>
        <PrimaryButton
          type="button"
          @click="submitRequestPassword"
          class="mt-2 w-full"
          :class="{ 'opacity-25': form.processing }"
          :disabled="form.processing"
        >
          inlogcode opvragen
        </PrimaryButton>
      </div>
    </form>
  </AuthenticationCard>
  <Banner />
</template>
