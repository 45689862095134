<script setup lang="ts">
import AssessmentLayout from "@/Layouts/AssessmentLayout.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import OntwikkelAssessmentIntroduction from "@/Components/OntwikkelAssessmentIntroduction.vue";
import { useForm } from "@inertiajs/vue3";
import useGradients from "../../composables/gradients";
/* define props title, introduction and sections */
const { assessment } = defineProps<{
    assessment: object
}>();

const { id, name, introduction, sections } = assessment;
const form = useForm({
    assessmentId: id,
});

const { linearGradient } = useGradients();

const startAssessment = () => {
    form.post(route("assessments.start", id));
};
</script>
<template>
    <AssessmentLayout :title="name" :isIntro="true">
        <!-- show title, introduction and sections -->
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                {{ name }}
            </h2>
        </template>
        <!-- show introduction and sections -->
        <OntwikkelAssessmentIntroduction />
        <template #next>
            <form @submit.prevent="startAssessment">
                <PrimaryButton>Start</PrimaryButton>
            </form>
        </template>
    </AssessmentLayout>
</template>
