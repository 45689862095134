<script setup lang="ts">
import { ref, computed } from "vue";
import ManagerLayout from "@/Layouts/ManagerLayout.vue";
import ListWithSearch from "../../Components/ListWithSearch.vue";
import NavLink from "../../Components/NavLink.vue";
import PrimaryButton from "../../Components/PrimaryButton.vue";
import SecondaryButton from "../../Components/SecondaryButton.vue";
import { formatDate } from "../../composables/formatDate.js";
import { usePage, useForm } from "@inertiajs/vue3";
import CreateCompany from "../Companies/partials/CreateCompany.vue";
import CreateTeam from "../Companies/partials/CreateTeam.vue";
import TeamTile from "./Partials/TeamTile.vue";

const props = defineProps<{ teams: Array<{ name: string, company_name: string, company_id: number }>, permissions: any, companies: any }>();

const selectedCompanies = ref(props.companies.map((company) => company.name));
function selectCompany(company) {
  if (selectedCompanies.value.length === props.companies.length) {
    selectedCompanies.value = [];
  }
  if (selectedCompanies.value.includes(company.name)) {
    selectedCompanies.value = selectedCompanies.value.filter(
      (item) => item !== company.name
    );
  } else {
    selectedCompanies.value.push(company.name);
  }
}
const searchFunction = (team, search) => {
  return team.name.toLowerCase().includes(search.toLowerCase()) && (selectedCompanies.value.length === 0 ||
    selectedCompanies.value.includes(team.company_name));
}

const list = ref(null);
const page = usePage();
const searchTeams = computed(() => {
  if (list.value !== null) {
    return list.value.searchList;
  } else {
    return page.props.teams;
  }
});

const isCreatingCompany = ref(false);
const isCreatingTeam = ref(false);
function close() {
  isCreatingTeam.value = false;
}

const date = s => formatDate(s);
</script>

<template>
  <ManagerLayout title="Teams">
    <template #header>
      <h2 class="font-semibold text-xl text-gray-800 leading-tight">Teams</h2>
    </template>

    <div class="bg-white p-2 border rounded shadow-sm">
      <div class="max-w-7xl full mx-auto py-10 sm:px-6 lg:px-8">
        <h3 class="text-lg font-medium leading-6 text-gray-900 mb-3 mr-3 inline-block">Bedrijven: </h3>
        <template v-for="(company, key) in companies">
          <!-- span with company name as filter for teams -->

          <button @click="selectCompany(company)"
                  class="text-sm border uppercase font-medium border-gray-300 text-gray-800 p-2 mr-4 mb-3 text-center rounded-lg"
                  :class="{ 'bg-gray-200': selectedCompanies.includes(company.name) }">{{
                    company.name
                  }}</button>
        </template>
        <SecondaryButton v-if="$page.props.permissions.canAddCompany" @click="isCreatingCompany = true">+ Maak bedrijf aan
        </SecondaryButton>
        <CreateCompany :show="isCreatingCompany"
                       @close="isCreatingCompany = false" />
        <CreateTeam :show="isCreatingTeam" @close="isCreatingTeam = false" />
        <ListWithSearch ref="list" :list="Object.values(teams)" :searchLabel="'Zoek een team'"
                        :searchPlaceholder="'team'"
                        :searchFunction="searchFunction">
          <!-- big button on hoover change color and bigger shadow -->
          <SecondaryButton class="w-64 bg-blue-150 pb-9 text-center flex-col justify-center shadow hover:shadow-lg hover:bg-blue-100 active:bg-blue-100 active:shadow-none"
                           v-if="$page.props.permissions.canAddTeams"
                           :href="route('teams.create')"
                           @click="isCreatingTeam = true">
            <span class="text-7xl"> + </span>
            <span>Voeg team toe</span>
          </SecondaryButton>
          <template v-for="(team, key) in searchTeams" :key="key">
            <TeamTile :team="team" :editable="$page.props.permissions.canManageTeams" />
          </template>
          <template #empty>
            Er zijn geen teams
          </template>
          <template #zeroResults>
            Geen team gevonden
          </template>
          <template #showAllButton>
            Laat alle teams zien
          </template>
        </ListWithSearch>
        <div class="w-full mt-4">
          <PrimaryButton :type="'link'" class="ml-2" v-if="$page.props.permissions.canBuyTeams"
                         :href="route('teams.buy')">
            Koop toegang tot extra team</PrimaryButton>
        </div>
      </div>
    </div>
  </ManagerLayout>
</template>

<style scoped>
/* change bg h2 and h3 to blue-100 when hovering parent a */
a:hover h2,
a:hover h3 {
  @apply bg-blue-100;
}
</style>