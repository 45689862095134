<script setup lang="ts">
import { onMounted, ref, computed, watch } from 'vue';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const props = defineProps<{
    data: Array<number>,
}>();

const datasets = computed(() => {
    return [{
        label: 'Gemiddeld werk/denk niveau',
        data: props.data,
        backgroundColor: [
            '#36AE42',
            '#23C6BC',
            '#1C99D9',
            '#F27B24',
            '#C52A2A',
            '#8832BD',
            '#DEC333'
        ],
    }]
})

const options = {
    plugins: {
        legend: {
            display: false,
        }
    },
    scales: {
        y: {
            suggestedMax: 5,
            ticks: {
                precision: 0,
                padding: 20,
            }
        },
        x: {
            stepSize: 1,
            grid: {
                display: false,
            },
        }
    }

}


const canvas = ref(null);
const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})

let chart = ref(null);
const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxAmounts = canvas.value.getContext('2d');
    chart.value = new Chart(ctxAmounts, {
        type: 'bar',
        options: options,
        data: {
            labels: ['Primair', 'MBO', 'MBO+', 'HBO', 'HBO+', 'WO'],
            datasets: datasets.value
        }
    })
}
</script>

<template>
    <canvas ref="canvas" id="capacity-amounts-chart" width="250" height="100"></canvas>
</template>
