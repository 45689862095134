<script lang="ts" setup>
import { ref, computed, defineEmits } from 'vue';
import useAutofocus from '../composables/autofocus';

type MostAnswer = number | null
type LeastAnswer = number | null

const props = withDefaults(defineProps<{ labels?: [string, string], items: Array<{ answer_title: string, id: number }>, answer?: [LeastAnswer, MostAnswer] }>(), {
    answer: () => [null, null]
})

const mostLabel = computed(() => Array.isArray(props.labels) ? props.labels[0] : 'meest')
const leastLabel = computed(() => Array.isArray(props.labels) ? props.labels[1] : 'minst')

const emit = defineEmits(['update:answer'])

const userAnswer = ref<[number, number]>([...props.answer])


const leastAnswer = computed({
    get: () => {
        if (props.answer[0] === null) {
            return userAnswer.value[0]
        } else {
            return props.answer[0]
        }
    },
    set: (val) => {
        if (mostAnswer.value === null) {
            userAnswer.value = [val, mostAnswer.value]
        } else {
            emit('update:answer', [val, mostAnswer.value])
        }
    }
})

const mostAnswer = computed({
    get: () => {
        if (props.answer[1] === null) {
            return userAnswer.value[1]
        } else {
            return props.answer[1]
        }
    },
    set: (val) => {
        if (leastAnswer.value === null) {
            userAnswer.value = [leastAnswer.value, val]
        } else {
            emit('update:answer', [leastAnswer.value, val])
        }
    }
})

// autofocus to get the first radio button to autofocus
const focus = useAutofocus();


</script>
<template>
    <div class="flex flex-col mb-4 w-full max-w-[520px] text-black mx-auto">
        <div class="flex content-between text-white">
            <div class="w-1/6 flex flex-col text-center p-2">
                <font-awesome-icon class="w-8 h-8 mx-auto" icon="fas fa-thumbs-up text-green-400"></font-awesome-icon>
                <p class="mt-3">{{ mostLabel }}</p>
                <i class="fas fa-caret-down text-gray-800 mt-3"></i>
            </div>
            <div class="w-2/3"></div>
            <div class="flex flex-col w-1/6 text-center p-2">
                <font-awesome-icon class="h-8 h-8 mx-auto" icon="fas fa-thumbs-down text-red-400"></font-awesome-icon>
                <p class="mt-3">{{ leastLabel }}</p>
                <i class="fas fa-caret-down text-gray-800 mt-3"></i>
            </div>
        </div>
        <div v-for="({ answer_title, id }, index) in props.items" class="flex content-between -mt-6">
            <div class="w-1/6 flex content-center">
                <label class="w-full h-3/5 radio flex items-center justify-around my-6">
                        <input class="radio bg-white border-white w-4 h-4" name="right" type="radio" :disabled="leastAnswer == id"
                                            :id="index + `_most`" v-model="mostAnswer" :value="id" :autofocus="index === 0 ? true : null">
                        <span class="checkmark bg-white border border-gray-400 absolute top-0 left-0"></span>
                        </label>
                        </div>
                                <div class="w-2/3 p-3 bg-white rounded-lg shadow text-center my-6 text-base leading-relaxed ">
                            {{ answer_title }}
                        </div>
                        <div class="w-1/6 flex content-center">
                            <label class="w-full h-3/5 radio flex items-center justify-around my-6">
                                <input class="radio bg-white border-white w-4 h-4" name="left" type="radio" :disabled="mostAnswer == id"
                                :id="index + `_least`" v-model="leastAnswer" :value="id">
                    <span class="checkmark bg-white border border-gray-400 absolute top-0 left-0"></span>
                </label>
            </div>
        </div>
    </div>
</template>