<script setup lang="ts">
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import { useForm, usePage } from "@inertiajs/vue3";
import ActionMessage from "@/Components/ActionMessage.vue";
import ActionSection from "@/Components/ActionSection.vue";
import ConfirmationModal from "@/Components/ConfirmationModal.vue";
import DangerButton from "@/Components/DangerButton.vue";
import DialogModal from "@/Components/DialogModal.vue";
import FormSection from "@/Components/FormSection.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import SectionBorder from "@/Components/SectionBorder.vue";
import TextInput from "@/Components/TextInput.vue";
import { remove } from "@vue/shared";

const props = defineProps({
  team: Object,
  availableAssessments: Array,
  userPermissions: Object,
});

const addAssessmentToUsersForm = useForm('addAssessment', {
  assessee: [],
  assessmentIds: [],
});

const assessee = ref([]);
const assessmentIds = ref([]);

const selectForAssessment = (user, assessments) => {
  if (assessee.value.includes(user)) {
    const index = assessee.value.indexOf(user);
    assessee.value.splice(index, 1);
  } else {
    assessee.value.push(user);
  }
  assessmentIds.value = assessments.map((a) => a.id);
};

const addAssessmentToUsers = () => {
  addAssessmentToUsersForm
    .transform((data) => {
      return { ...data, assessee: assessee.value, assessmentIds: assessmentIds.value };
    })
    .post(route("assessments.access.add"), {
      preserveScroll: true,
      onSuccess: () => addAssessmentToUsersForm.reset(),
    });
};

const removeAssessmentForm = useForm('removeAssessment', {
  assessee: "",
  assessmentId: "",
});

const AssessmentBeingRemoved = ref<null | [string, string]>(null);

const confirmAssessmentRemoval = (user, assessment) => {
  AssessmentBeingRemoved.value = [user, assessment];
};

const removeAssessmentAccess = () => {
  removeAssessmentForm
    .transform((data) => {
      return {
        ...data,
        assessee: AssessmentBeingRemoved.value[0],
        assessmentId: AssessmentBeingRemoved.value[1],
      };
    })
    .post(route("assessments.access.destroy"), {
      preserveScroll: true,
      onSuccess: () => {
        removeAssessmentForm.reset()
        AssessmentBeingRemoved.value = null
      },
    });
};

// translate current_status to dutch
// 'invited', 'idle', 'in_progress', 'completed', 'expired'
const progressTranslations = ref(
  new Map<string, string>([
    ["invited", "Uitgenodigd"],
    ["idle", "Inactief"],
    ["in_progress", "bezig"],
    ["completed", "Voltooid"],
    ["expired", "Verlopen"],
  ])
);
</script>

<template>
  <div>
    <div v-if="userPermissions.canAddAssessment && team.users.length > -1">

      <!-- Add reviewer -->
      <FormSection @submitted="addAssessmentToUsers">
        <template #title> Stel assessment open </template>

        <template #description>
          Geef toegang tot een assessment aan een of meerdere gebruikers.
        </template>

        <template #form>
          <div class="col-span-6">
            <div class="max-w-xl text-sm text-gray-600">
              Selecteer de gebruikers die toegang moeten krijgen tot het assessment.
            </div>
            <div class="col-span-6 sm:col-span-4">
              <InputError
                          :message="addAssessmentToUsersForm.errors.assessee"
                          class="mt-2" />
            </div>
          </div>

          <div class="space-y-6 col-span-6">
            <div
                 v-for="member in team.users"
                 :key="member.id"
                 class="flex items-center justify-between"
                 :class="{
                   ' bg-gray-200': addAssessmentToUsersForm
                     .data()
                     .assessee.includes(member.id),
                 }">
              <div class="flex items-center">
                <img class="w-8 h-8 rounded-full" :src="member.profile_photo_url" :alt="member.name" />
                <div class="ml-4">
                  {{ member.name }}
                </div>
              </div>

              <div class="flex items-center">
                <div class="flex items-center">
                  <span class="text-sm text-gray-400">
                    {{
                      member.assessment_status.length > 0
                      ? progressTranslations.get(member.assessment_status[0].current_status)
                      : ""
                    }}
                  </span>
                </div>
                <!-- Select for access -->
                <button v-show="userPermissions.canAddAssessment &&
                    member.assessment_status.length === 0 &&
                    availableAssessments.length
                    " class="ml-2 text-sm text-gray-400 underline"
                        @click="selectForAssessment(member.id, availableAssessments)">
                  Selecteer
                </button>

                <!-- Remove access, only show when user has access -->
                <button v-show="userPermissions.canAddAssessment && member.assessment_status.length > 0"
                        class="cursor-pointer ml-6 text-sm text-red" @click="
                          confirmAssessmentRemoval(
                            member.id,
                            member.assessmentList[0].assessment_id
                          )
                          ">
                  Verwijder
                </button>
              </div>
            </div>
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="addAssessmentToUsersForm.recentlySuccessful" class="mr-3">
            Toegevoegd.
          </ActionMessage>
          <ActionMessage :on="removeAssessmentForm.recentlySuccessful" class="mr-3">
            Verwijderd.
          </ActionMessage>

        </template>
      </FormSection>
    </div>

    <!-- Remove assessment access Modal -->
    <ConfirmationModal :show="AssessmentBeingRemoved" @close="AssessmentBeingRemoved = null">
      <template #title> Verwijder assessment Toegang </template>

      <template #content>
        Weet je zeker dat je deze gebruiker geen toegang meer wilt geven tot het assessment?
      </template>

      <template #footer>
        <SecondaryButton @click="AssessmentBeingRemoved = null"> Annuleer </SecondaryButton>
        <DangerButton
                      class="ml-3"
                      :class="{ 'opacity-25': removeAssessmentForm.processing }"
                      :disabled="removeAssessmentForm.processing"
                      @click="removeAssessmentAccess">
          Verwijder
        </DangerButton>
      </template>
    </ConfirmationModal>
  </div>
</template>
