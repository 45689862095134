<script setup>
import { ref } from "vue";
import { useForm, usePage, router } from "@inertiajs/vue3";
import ActionMessage from "@/Components/ActionMessage.vue";
import ActionSection from "@/Components/ActionSection.vue";
import ConfirmationModal from "@/Components/ConfirmationModal.vue";
import DangerButton from "@/Components/DangerButton.vue";
import DialogModal from "@/Components/DialogModal.vue";
import FormSection from "@/Components/FormSection.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import SecondaryButton from "@/Components/SecondaryButton.vue";
import SectionBorder from "@/Components/SectionBorder.vue";
import TextInput from "@/Components/TextInput.vue";

const props = defineProps({
  team: Object,
  availableRoles: Object,
  userPermissions: Object,
});

const addTeamReviewerForm = useForm({
  email: "",
  role: Object.keys(props.availableRoles)[0],
});

const updateRoleForm = useForm({
  role: null,
});

const leaveAsReviewerForm = useForm({
  leaveAsReviewer: false,
});
const removeTeamReviewerForm = useForm({
  removeTeamReviewer: false,
});

const currentlyManagingRole = ref(false);
const managingRoleFor = ref(null);
const confirmingLeavingTeam = ref(false);
const teamReviewerBeingRemoved = ref(null);

const addTeamReviewer = () => {
  addTeamReviewerForm.post(route("team-reviewers.store", props.team), {
    errorBag: "addTeamReviewer",
    preserveScroll: true,
    onSuccess: () => addTeamReviewerForm.reset(),
  });
};

const cancelReviewInvitation = (invitation) => {
  router.delete(route("review-invitations.destroy", invitation), {
    preserveScroll: true,
  });
};

const manageRole = (teamReviewer) => {
  managingRoleFor.value = teamReviewer;
  updateRoleForm.role = teamReviewer.membership.role;
  currentlyManagingRole.value = true;
};

const updateRole = () => {
  updateRoleForm.put(route("team-reviewers.update", [props.team, managingRoleFor.value]), {
    preserveScroll: true,
    onSuccess: () => (currentlyManagingRole.value = false),
  });
};

const confirmLeavingTeam = () => {
  confirmingLeavingTeam.value = true;
};

const leaveAsReviewer = () => {
  leaveAsReviewerForm.delete(
    route("team-reviewers.destroy", [props.team, usePage().props.auth.user])
  );
};

const confirmTeamReviewerRemoval = (teamReviewer) => {
  teamReviewerBeingRemoved.value = teamReviewer;
};

const removeTeamReviewer = () => {
  removeTeamReviewerForm.delete(
    route("team-reviewers.destroy", [props.team, teamReviewerBeingRemoved.value]),
    {
      errorBag: "removeTeamReviewer",
      preserveScroll: true,
      preserveState: true,
      onSuccess: () => (teamReviewerBeingRemoved.value = null),
    }
  );
};

const displayableRole = (role, availableRoles) => {
  // check if role is available in availableRoles.
  // if not, return the role itself

  if (!availableRoles[role]) {
    return role;
  } else {
    return availableRoles[role];
  }
};
</script>

<template>
  <div>
    <div v-if="userPermissions.canAddReviewers">

      <!-- Add reviewer -->
      <FormSection @submitted="addTeamReviewer">
        <template #title> Nodig reviewer uit </template>

        <template #description>
          Voeg iemand toe die de groepsleden gaat beoordelen
        </template>

        <template #form>
          <div class="col-span-6">
            <div class="max-w-xl text-sm text-gray-600">
              Geef het email adres op van de persoon die gaat beoordelen.
            </div>
          </div>

          <!-- Reviewer Email -->
          <div class="col-span-6 sm:col-span-4">
            <InputLabel for="email" value="Email" />
            <TextInput
                       id="email"
                       v-model="addTeamReviewerForm.email"
                       type="email"
                       class="mt-1 block w-full" />
            <InputError :message="addTeamReviewerForm.errors.email" class="mt-2" />
          </div>

          <!-- Role -->
          <div v-if="availableRoles.length > 1" class="col-span-6 lg:col-span-4">
            <InputLabel for="roles" value="Rol" />
            <InputError :message="addTeamReviewerForm.errors.role" class="mt-2" />

            <div
                 class="relative z-0 mt-1 border border-gray-200 rounded-lg cursor-pointer">
              <button
                      v-for="(role, i) in availableRoles"
                      :key="role.key"
                      type="button"
                      class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                      :class="{
                        'border-t border-gray-200 rounded-t-none': i > 0,
                        'rounded-b-none': i != Object.keys(availableRoles).length - 1,
                      }"
                      @click="addTeamReviewerForm.role = role.key">
                <div
                     :class="{
                       'opacity-50':
                         addTeamReviewerForm.role && addTeamReviewerForm.role != role.key,
                     }">
                  <!-- Role Name -->
                  <div class="flex items-center">
                    <div
                         class="text-sm text-gray-600"
                         :class="{ 'font-semibold': addTeamReviewerForm.role == role.key }">
                      {{ role.name }}
                    </div>

                    <svg
                         v-if="addTeamReviewerForm.role == role.key"
                         class="ml-2 h-5 w-5 text-green-400"
                         fill="none"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         stroke="currentColor"
                         viewBox="0 0 24 24">
                      <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>

                  <!-- Role Description -->
                  <div class="mt-2 text-xs text-gray-600 text-left">
                    {{ role.description }}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </template>

        <template #actions>
          <ActionMessage :on="addTeamReviewerForm.recentlySuccessful" class="mr-3">
            Toegevoegd.
          </ActionMessage>

          <PrimaryButton
                         :class="{ 'opacity-25': addTeamReviewerForm.processing }"
                         :disabled="addTeamReviewerForm.processing">
            Nodig uit
          </PrimaryButton>
        </template>
      </FormSection>
    </div>

    <div v-if="team.review_invitations.length > 0 && userPermissions.canRemoveReviewers">
      <SectionBorder />

      <!-- Reviewer Invitations -->
      <ActionSection class="mt-10 sm:mt-0">
        <template #title> Review uitnodigingen </template>

        <template #description>
          Naar deze adressen zijn uitnodigingen verstuurd waar nog niet op gereageerd is.
        </template>

        <!-- Pending Reviewer Invitation List -->
        <template #content>
          <div class="space-y-6">
            <div
                 v-for="invitation in team.review_invitations"
                 :key="invitation.id"
                 class="flex items-center justify-between">
              <div class="text-gray-600">
                {{ invitation.email }}
              </div>

              <div class="flex items-center">
                <!-- Cancel Team Invitation -->
                <button
                        v-if="userPermissions.canRemoveReviewers"
                        class="cursor-pointer ml-6 text-sm text-red-500 focus:outline-none"
                        @click="cancelReviewInvitation(invitation)">
                  Annuleer
                </button>
              </div>
            </div>
          </div>
        </template>
      </ActionSection>
    </div>

    <div v-if="team.reviewers.length > 0">
      <SectionBorder />

      <!-- Manage Reviewers -->
      <ActionSection class="mt-10 sm:mt-0">
        <template #title> Reviewers </template>

        <template #description> Iedereen die een reviewer is. </template>

        <!-- Team Member List -->
        <template #content>
          <div class="space-y-6">
            <div
                 v-for="reviewer in team.reviewers"
                 :key="reviewer.id"
                 class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                     class="w-8 h-8 rounded-full"
                     :src="reviewer.profile_photo_url"
                     :alt="reviewer.name" />
                <div class="ml-4">
                  {{ reviewer.name }}
                </div>
              </div>

              <div class="flex items-center">
                <!-- Manage Team Reviewer Role -->
                <button
                        v-if="userPermissions.canAddReviewers && availableRoles.length > 0"
                        class="ml-2 text-sm text-gray-400 underline"
                        @click="manageRole(reviewer)">
                  {{ displayableRole(reviewer.role, availableRoles) }}
                </button>

                <div v-else-if="availableRoles.length > 0" class="ml-2 text-sm text-gray-400">
                  {{ displayableRole(reviewer.role, availableRoles) }}
                </div>


                <!-- Remove Team Member -->
                <button
                        v-else-if="userPermissions.canRemoveReviewers"
                        class="cursor-pointer ml-6 text-sm text-red-500"
                        @click="confirmTeamReviewerRemoval(reviewer)">
                  Verwijder
                </button>
              </div>
            </div>
          </div>
        </template>
      </ActionSection>
    </div>

    <!-- Role Management Modal -->
    <DialogModal :show="currentlyManagingRole" @close="currentlyManagingRole = false">
      <template #title> Manage Role </template>

      <template #content>
        <div v-if="managingRoleFor">
          <div class="relative z-0 mt-1 border border-gray-200 rounded-lg cursor-pointer">
            <button
                    v-for="(role, i) in availableRoles"
                    :key="role.key"
                    type="button"
                    class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                    :class="{
                      'border-t border-gray-200 rounded-t-none': i > 0,
                      'rounded-b-none': i !== Object.keys(availableRoles).length - 1,
                    }"
                    @click="updateRoleForm.role = role.key">
              <div
                   :class="{
                     'opacity-50': updateRoleForm.role && updateRoleForm.role !== role.key,
                   }">
                <!-- Role Name -->
                <div class="flex items-center">
                  <div
                       class="text-sm text-gray-600"
                       :class="{ 'font-semibold': updateRoleForm.role === role.key }">
                    {{ role.name }}
                  </div>

                  <svg
                       v-if="updateRoleForm.role === role.key"
                       class="ml-2 h-5 w-5 text-green-400"
                       fill="none"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       stroke="currentColor"
                       viewBox="0 0 24 24">
                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>

                <!-- Role Description -->
                <div class="mt-2 text-xs text-gray-600">
                  {{ role.description }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </template>

      <template #footer>
        <SecondaryButton @click="currentlyManagingRole = false">
          Annuleer
        </SecondaryButton>

        <PrimaryButton
                       class="ml-3"
                       :class="{ 'opacity-25': updateRoleForm.processing }"
                       :disabled="updateRoleForm.processing"
                       @click="updateRole">
          Opslaan
        </PrimaryButton>
      </template>
    </DialogModal>

    <!-- Leave Team Confirmation Modal -->
    <ConfirmationModal
                       :show="confirmingLeavingTeam"
                       @close="confirmingLeavingTeam = false">
      <template #title> Verlaat Team </template>

      <template #content> Weet je zeker dat je dit team wilt verlaten </template>

      <template #footer>
        <SecondaryButton @click="confirmingLeavingTeam = false">
          Annuleer
        </SecondaryButton>

        <DangerButton
                      class="ml-3"
                      :class="{ 'opacity-25': leaveAsReviewerForm.processing }"
                      :disabled="leaveAsReviewerForm.processing"
                      @click="leaveAsReviewer">
          Verlaat
        </DangerButton>
      </template>
    </ConfirmationModal>

    <!-- Remove Reviewer Confirmation Modal -->
    <ConfirmationModal
                       :show="teamReviewerBeingRemoved"
                       @close="teamReviewerBeingRemoved = null">
      <template #title> Verwijder Reviewer </template>

      <template #content>
        Weet je zeker dat je deze reviewer wil verwijderen?
      </template>

      <template #footer>
        <SecondaryButton @click="teamReviewerBeingRemoved = null">
          Annuleer
        </SecondaryButton>

        <DangerButton
                      class="ml-3"
                      :class="{ 'opacity-25': removeTeamReviewerForm.processing }"
                      :disabled="removeTeamReviewerForm.processing"
                      @click="removeTeamReviewer">
          Verwijder
        </DangerButton>
      </template>
    </ConfirmationModal>
  </div>
</template>
