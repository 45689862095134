<script lang="ts" setup>
import { computed, defineEmits, onMounted } from 'vue';
import useAutofocus from '../composables/autofocus';

const props = withDefaults(defineProps<{ id: number, question: string, options?: Array<string>, answer?: string | number, showLabels: boolean, questionNumber: number, autofocus?: boolean }>(),
    { autofocus: () => false, showLabels: () => true, answer: () => null, options: () => null })

const emit = defineEmits(['update:answer'])

const defaultScale = ['helemaal mee oneens', 'oneens', 'neutraal', 'eens', 'helemaal mee eens']

const scale = computed(() => {
    if (Array.isArray(props.options && props.options.length > 1)) {
        return props.options
    }
    return defaultScale
})

const radioAnswer = computed({
    get: () => { return props.answer },
    set: (value) => emit('update:answer', value)
})

// autofocus to get the first radio button to autofocus
const focus = useAutofocus();

</script>
<template>
    <div class="w-full lg:flex bg-gray-100 text-black">
        <div class="px-6 py-3 w-full lg:w-1/2 bg-white flex justify-items-center">
            <p class="my-auto -indent-8 pl-8">
                            <span class="mr-1">{{ questionNumber.toString() }}.</span> {{ question }}
                        </p>
                    </div>
                    <div class="w-full md:w-4/5 lg:w-1/2 py-3 justify-end flex flex-row items-end ml-auto lg:ml-0 px-4">
                        <div class="w-full flex flex-row justify-between items-end lg:justify-start lg:gap-2">
                            <label class="radio text-center" :key="index" :id="index.toString()" v-for="(item, index) in scale">
                                <span :class="{ 'lg:invisible': !showLabels }"
                                      class="inline-block w-full text-gray-700 text-sm lg:text-base">{{ item
                                      }}</span>
                                <input :name="id.toString()" type="radio" :value="item"
                                                       v-model="radioAnswer" :autofocus="index === 0 && autofocus ? true : null">
                                <span class="checkmark bg-white border border-gray-400 absolute top-0 left-0"></span>
                            </label>
                        </div>
                    </div>
                </div>
</template>
