<script setup lang="ts">
import { ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';


const props = defineProps<{
    section: {
        instruction_title: string;
        instruction_body: string;
    };
}>();


const open = ref(false);
function close() {
    open.value = false;
}

</script>
<template>
    <div>
        <p v-click-away="close" @click.stop="open = !open"
            class="text-right ml-auto text-gray-400 mb-3 text-sm hover:cursor-pointer" :class="{
                'w-1/3' : !open,
                'whitespace-nowrap': !open,
                'overflow-ellipsis': !open,
                'overflow-hidden': !open,
                
            }">
            <font-awesome-icon icon="fa-solid fa-info-circle" />
            {{ section.instruction_body }}
    
        </p>
        </div>
</template>
