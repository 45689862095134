<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import { Head, Link } from "@inertiajs/vue3";
import ApplicationMark from "@/Components/ApplicationMark.vue";
import Banner from "@/Components/Banner.vue";
import SideBar from "@/Components/SideBar.vue";
import SidebarItem from "../Components/SidebarItem.vue";
import TopNavigation from "../Components/TopNavigation.vue";

defineProps({
  title: String,
});

const sidebar = ref(null);

const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
  Inertia.put(
    route("current-team.update"),
    {
      team_id: team.id,
    },
    {
      preserveState: false,
    }
  );
};

const logout = () => {
  Inertia.post(route("logout"));
};
</script>

<template>
  <div>

    <Head :title="title" />


    <div class="h-screen bg-blue-50 ml-[56px]" :class="{
      'md:ml-[56px]': sidebar && sidebar.isClosedDesktop,
      'md:ml-[256px]': sidebar && !sidebar.isClosedDesktop
    }">
      <div class="flex flex-col h-full pt-[72px] " :class="{
        'pt-[120px]': $slots.navigation
      }">
        <!-- Page Heading -->
        <div class="w-full">
          <header v-if="$slots.header" class="max-w-7xl mx-auto pl-6 pt-6 pb-3">
            <slot name="header" />
          </header>

          <!-- Page Content -->
          <main class="max-w-7xl mx-auto p-3 bg-blue-50">
            <slot />
          </main>
        </div>
        <div class="w-full fixed top-[72px] pr-0">
          <nav v-if="$slots.navigation">
            <slot name="navigation" />
          </nav>
        </div>
      </div>


      <SideBar ref="sidebar">
        <SidebarItem class="mt-8" :href="route('dashboard')" :current="route().current('dashboard*')"
                     icon="fa-solid fa-home">
          Dashboard</SidebarItem>
        <SidebarItem v-if="$page.props.permissions.canViewTeams" :href="route('teams')" :current="route().current('teams*')"
                     icon="fa-solid fa-users">
          Teams
        </SidebarItem>
        <SidebarItem v-if="$page.props.permissions.canManageTeams" :href="route('teams')" :current="route().current('teams*')" icon="fa-solid fa-sliders">
          Teamsbeheer
          <template #subitems>
            <div v-if="route().current('teams.edit')">
              <SidebarItem class="!font-light" href="#team">
                Team
              </SidebarItem>
              <SidebarItem class="!font-light" href="#assessments">
                Assessments
              </SidebarItem>
              <SidebarItem class="!font-light" href="#beoordelaars">
                Beoordelaars
              </SidebarItem>
            </div>
          </template>
        </SidebarItem>
        <hr class="mx-4 mt-8">
        <SidebarItem :href="route('superapp')" icon="fa-solid fa-arrow-up-from-bracket"> Andere modules </SidebarItem>
      </SideBar>
    </div>
    <TopNavigation>

    </TopNavigation>
    <Banner />
  </div>
</template>
<style lang="css">
:root {
  --menu-height: 72px;
}
</style>
