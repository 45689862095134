<script setup lang="ts">
import { defineProps, ref, onMounted } from 'vue';
import DeleteTeamForm from '@/Pages/Teams/Partials/DeleteTeamForm.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import TeamAssessmentManager from './Partials/TeamAssessmentManager.vue';
import TeamMemberManager from '@/Pages/Teams/Partials/TeamMemberManager.vue';
import TeamReviewerManager from '@/Pages/Teams/Partials/TeamReviewerManager.vue';
import UpdateTeamNameForm from '@/Pages/Teams/Partials/UpdateTeamNameForm.vue';
import ManagerLayout from '../../Layouts/ManagerLayout.vue';

defineProps({
    team: Object,
    availableProfiles: Array,
    availableRoles: Array,
    availableReviewRoles: Array,
    availableAssessments: Array,
    permissions: Object,
});
const tabs: Array<[string, string]> = [
    ['#team', 'Team'],
    ['#assessments', 'Assessments'],
    ['#beoordelaars', 'Beoordelaars'],
];
const getHash = () => {
    // if location hash in tabs, return hash, else return first tab
    if (tabs.some(([hash]) => hash === window.location.hash)) {
        return window.location.hash;
    }
    return tabs[0][0];
}
const tab = ref('#team');
onMounted(() => {
    tab.value = getHash();
})


const updateTab = (value) => {
    window.location.hash = value;
}
</script>

<template>
    <ManagerLayout title="Team Settings">
        <template #navigation>
            <v-tabs v-model="tab"
                    class="bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 shadow rounded text-gray-700 w-full">
                <v-tab v-for="( [hash, label] ) in  tabs " @click="updateTab(hash)" :key="hash" :to="hash"
                       :class="{ 'active': tab === hash }">
                    {{ label }}
                </v-tab>
            </v-tabs>
        </template>
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Beheer {{ team.name }}
            </h2>
        </template>

        <div>
            <div class="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">

                <v-window v-model="tab">
                    <v-window-item value="#team">
                        <UpdateTeamNameForm class="mt-10 sm:mt-0" :team="team" :permissions="permissions" />
                        <TeamMemberManager
                                           id="members"
                                           class="mt-10 sm:mt-0"
                                           :team="team"
                                           :available-profiles="availableProfiles"
                                           :available-roles="availableRoles"
                                           :user-permissions="permissions" />
                    </v-window-item>

                    <v-window-item value="#assessments">
                        <TeamAssessmentManager
                                               id="assessments"
                                               class="mt-10 sm:mt-0"
                                               :team="team"
                                               :available-assessments="availableAssessments"
                                               :user-permissions="permissions" />
                    </v-window-item>

                    <v-window-item value="#beoordelaars">
                        <TeamReviewerManager
                                             id="reviewers"
                                             class="mt-10 sm:mt-0"
                                             :team="team"
                                             :available-roles="availableReviewRoles"
                                             :user-permissions="permissions" />
                    </v-window-item>
                </v-window>

                <template v-if="permissions.canDeleteTeam && !team.personal_team">
                    <SectionBorder />

                    <DeleteTeamForm class="mt-10 sm:mt-0" :team="team" />
                </template>
            </div>
        </div>
    </ManagerLayout>
</template>
