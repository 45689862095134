import "./bootstrap";
import "../css/app.css";
import { version } from "../../package.json";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import * as Sentry from "@sentry/vue";
import Tooltip from "@programic/vue3-tooltip";
// Import the CSS or use your own!
import '/resources/css/vue3-tooltip.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faAnglesLeft,
    faAnglesRight,
    faArrowUpFromBracket,
    faArrowRightFromBracket,
    faCircleCheck,
    faHome,
    faInfoCircle,
    faSliders,
    faPencil,
    faMugHot,
    faThumbsUp,
    faThumbsDown,
    faSpinner,
    faPenToSquare,
    faChevronDown,
    faCircleUser,
    faUsers,
    faFaceSmile as faFaceSmileSolid,
    faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFaceSmile as faFaceSmileRegular,
    faCircleQuestion as faCircleQuestionRegular,
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faAnglesLeft,
    faAnglesRight,
    faHome,
    faSliders,
    faArrowUpFromBracket,
    faArrowRightFromBracket,
    faCircleCheck,
    faInfoCircle,
    faPencil,
    faMugHot,
    faThumbsUp,
    faThumbsDown,
    faSpinner,
    faChevronDown,
    faPenToSquare,
    faCircleUser,
    faUsers,
    faFaceSmileSolid,
    faFaceSmileRegular,
    faCircleQuestion,
    faCircleQuestionRegular,
);

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
    components,
    directives,
});

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

// Sentry.init({
//     app,
//     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
//     integrations: [
//         new Sentry.BrowserTracing({
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,

//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
// });

createInertiaApp({
    id: "app",
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.vue", { eager: true });
        return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(vuetify)
            .use(Tooltip, {
                trigger: "hoover",
                type: 'light',
            })
            .component("font-awesome-icon", FontAwesomeIcon)
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
