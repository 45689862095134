import { lowerCase } from "lodash"

export const potentialPerformance =  {
        "low_low": {
            "title": "Ongeschikt",
            "description": "past van nature niet op deze functie en laat ook in praktijk geen goede aansluiting zien. Voor deze medewerker volgt een traject van mobiliteit naar een andere functie binnen of buiten de organisatie."
        },
        "mid_low": {
            "title": "Onderpresteerder",
            "description": "past redelijk op deze functie, maar prestaties zijn onder de maat. Onderzoek of en hoe deze medewerker prestaties kan verbeteren. Wanneer dit onvoldoende oplevert volgt mobiliteit."
        },
        "high_low": {
            "title": "Starter of onderpresteerder",
            "description": "past van nature goed op deze functie, maar laat dat nog niet zien in prestaties. Begeleid deze medewerker intensief zodat prestaties verbeteren."
        },
        "low_mid": {
            "title": "Ondersteuner",
            "description": "functioneert voldoende met huidige takenpakket. Observeer bij veranderingen en begeleid of ondersteun waar nodig."
        },
        "mid_mid": {
            "title": "Kernmedewerker",
            "description": "Voert taken naar behoren uit. Kan verder ontwikkelen in huidige functie en daarmee beter presteren."
        },
        "high_mid": {
            "title": "Talent",
            "description": "Heeft veel in huis om verder te ontwikkelen binnen deze functie. Bied daarnaast perspectief voor de langere termijn."
        },
        "low_high": {
            "title": "Ervaren of hoge presteerder",
            "description": "Waardevolle medewerker die goed presteert. Kan verder ontwikkelen door andere taken op te pakken."
        },
        "mid_high": {
            "title": "Toppresteerder",
            "description": "Waardevolle medewerker die een belangrijke bijdrage levert aan de prestaties. Ga in overleg over de ambities van de medewerker zodat deze behouden blijft voor de organisatie en verder kan ontwikkelen."
        },
        "high_high": {
            "title": "Toptalent",
            "description": "Heeft veel in huis en presteert optimaal in de huidige functie. Zorg dat deze medewerker voldoende uitdaging heeft, snel kan doorgroeien en zo behouden blijft voor de organisatie."
        }
    }

export const dominantBehaviour = {
    "missing" : {
        "title": "Niet genoeg resultaten",
        "description": "In het team heeft een groot deel van de teamleden geen ontwikkelassessment gedaan. Hierdoor is het niet mogelijk om een uitspraak te doen."
    },
    "blue" : {
        "title": "Blauw",
        "description": "Het team is sterk in observeren, analyseren, ze zijn zorgvuldig, detailgericht en goed georganiseerd. Het team is formeel en rustig. Verder volgen ze graag regels en procedures of geven deze vorm. Aandachtspunten zijn de neiging om teveel te willen onderbouwen en verklaren en daardoor minder snel risico’s en besluiten durven nemen en hun terughoudendheid naar anderen."
    },
    "green": {
        "title": "Groen",
        "description": "Het team is sterk in het ondersteunen van anderen en heeft aandacht voor details. Het team is coöperatief, werkt rustig en gestaag door en is empathisch. Aandachtspunten zijn een lagere veranderingsbereidheid, actiebereidheid en moeilijker beslissingen kunnen nemen en prioriteren."
    },
    "red": {
        "title": "Rood",
        "description": "Het team is resultaatgericht, het wil graag presteren en houdt van uitdagingen en veranderingen. Het team is voornamelijk gefocust op hoofdlijnen. Verder willen ze controleren en durven ze te confronteren. Aandachtspunten zijn ongeduldigheid, te direct en veeleisend zijn naar andere stijlen en anderen teveel inzetten om te ondersteunen."
    },
    "yellow": {
        "title": "Geel",
        "description": "Het team is enthousiast, optimistisch, mens georiënteerd en creatief. Het team heeft veel behoefte aan contact en durft risico’s te nemen. Aandachtspunten zijn dat ze weinig detailgericht zijn, te impulsief en intuïtief kunnen handelen en chaotisch kunnen zijn."
    }
}


export function primaryMotivators ([first, second, ..._]) {
    const arr = [lowerCase(first), lowerCase(second)].sort();
    const description = {
        "economisch": {
            "esthetisch": "Het team is gericht op het werken aan een missie, waarbij zij zichzelf creatief kunnen ontplooien in het werk. Daarbij worden zaken verklaard en onderbouwd en is het voor het team belangrijk om kennis te verrijken.",
            "individualistisch": "Het team is gericht op resultaat, en werkt graag aan nieuwe uitdagingen met maximaal rendement. Het team houdt daarbij graag de controle. Het team is zelfstandig, inventief en durft risico’s te nemen.",
            "sociaal": "Het team is gericht op het bijdragen aan de efficiëntie van de organisatie. Het team is verantwoordelijk, respectvol, integer en ondersteunt graag anderen. Het team is zowel gericht op het resultaat als op de mensen die nodig zijn om dat resultaat te behalen.",
            "theoretisch": "Het team is gericht op het verklaren en onderbouwen, op innovatie en resultaat. Het team gaat graag uitdagingen aan en houdt daarbij het rendement in de gaten. Het team wil graag kennis verrijken en onderzoek doen.",
            "traditionalistisch": "Het team is gericht op resultaten en het bijdragen aan de efficiëntie van de organisatie, met name op langere termijn. Het team is verantwoordelijk, respectvol, integer en stelt kritische vragen."
        },
        "esthetisch": {
            "individualistisch": "Het team is gericht op het werken aan een missie, met name creatieve missies waarbij ruimte is voor zelfontplooiing. Het team stelt hoge eisen aan het eigen werk en houdt daarbij graag controle.",
            "sociaal": "Het team is gericht op anderen, het kan zich goed inleven in anderen, wil graag anderen ondersteunen en bijdragen aan een missie. Het team is creatief en wil zich graag ontplooien.",
            "theoretisch": "Het team is gericht op het werken aan een missie, waarbij zij zichzelf creatief kunnen ontplooien in het werk. Daarbij worden zaken verklaard en onderbouwd en is het voor het team belangrijk om kennis te verrijken.",
            "traditionalistisch": "Het team is gericht op sfeer en harmonie? … Het team handelt weloverwogen en consequent en heeft een sterk verantwoordelijkheidsgevoel. Het team handelt snel uit regels en richtlijnen en normen die zijn vastgesteld. Balans en harmonie tussen werk en privé goed houden. Strikt in vastgestelde normen en afspraken ook in werk-privé balans. Zorgen ervoor dat afspraken naar klanten en collega’s worden nagekomen en "
        },
        "individualistisch": {
            "sociaal": "Het team is gericht op anderen, het wil anderen graag ondersteunen. Het team houdt daarbij graag controle, opereert redelijk zelfstandig, is wilskrachtig en durft risico’s te nemen. Streven naar vooruitgang van het team.",
            "theoretisch": "Het team is gericht op zaken onderzoeken, verklaren en kennis verrijken. Het team houdt graag controle, opereert redelijk zelfstandig, is wilskrachtig en durft risico’s te nemen.",
            "traditionalistisch": "Het team is gericht op wat de organisatie van het team vraagt, hierop stelt het team prioriteiten en doet dat consequent. Het team is verantwoordelijk, respectvol en integer en neemt gecalculeerde risico’s."
        },
        "sociaal": {
            "theoretisch": "Het team is gericht op het verklaren en onderbouwen van zaken, het ondersteunt graag anderen en is gericht op samenwerking en kennisoverdracht. Het team is gericht op kwaliteit.",
            "traditionalistisch": "Het team is gericht op het ondersteunen van anderen, werkt graag met anderen samen en denkt aan de belangen van anderen. Het team is verantwoordelijk respectvol en integer. Het team kan prioriteiten stellen en is consequent."
        },
        "theoretisch": {
            "traditionalistisch": "Het team is gericht op het verklaren en onderbouwen van zaken, en het team is rationeel, verantwoordelijk en consequent. Het team doet objectief en grondig onderzoek en stelt kritische vragen.",
        }
    }
    
    return description[arr[0]][arr[1]]
}
export default {potentialPerformance, dominantBehaviour, primaryMotivators};
