<script setup lang="ts">
import TalentGraph from './TalentGraph.vue';
import { computed, ref } from 'vue';
const props = defineProps<{
    data: { [key: string]: number }
}>()

const talentNames = computed(() => Object.keys(props.data))

const colors = ref([
    '#1ED2CB',
    '#3E95D4',
    '#D65959',
    '#D7C525',
    '#B284B9'
])
</script>
<template>
    <div class="flex flex-wrap justify-center gap-5">
        <template v-for="(talent, index) in talentNames">
            <TalentGraph :name="talent" :score="props.data[talent]" :color="colors[index]"
                         class="flex flex-col items-center font-semibold justify-end"></TalentGraph>
        </template>
    </div>
</template>
