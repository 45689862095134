<script setup lang="ts">
import { ref, onMounted } from 'vue';
import AssessmentLayout from '../../Layouts/AssessmentLayout.vue';
import PrimaryButton from '../../Components/PrimaryButtonWhite.vue';
import { useConfetti } from '../../composables/confetti';
import { router } from '@inertiajs/vue3';
import useGradients from '../../composables/gradients';

const props = defineProps<{
    assessment: object;
}>();

const title = ref('Afgerond');

const { id: confettiId } = useConfetti('confetti-button');

function gotoResults() {
    setTimeout(() => {
        router.visit(route('team-members.index'));
    }, 300)
}

</script>
<template>
    <AssessmentLayout :title="title">
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                {{ title }}
            </h2>
        </template>
        <div class="w-full flex justify-center my-12">
            <img src="/confetti.png" class="h-16 transform scale -scale-x-100" />
            <img src="/confetti.png" class="h-16" />
        </div>
        <!-- show introduction and sections -->
        <div class="m-3 max-w-md mx-auto">
            <h3 class="text-lg font-medium mb-6">
                Je hebt de vragenlijst afgerond
            </h3>
            <p class="mb-3">
                Je hebt alle onderdelen afgerond! Je kunt hierna je resultaten inzien.
            </p>
            <div class="flex justify-center">
                <PrimaryButton :id="confettiId" @click="gotoResults">Naar resultaten</PrimaryButton>
            </div>

        </div>

        <template #next>
        </template>

    </AssessmentLayout>
</template>