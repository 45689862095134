<script setup lang="ts">
import { defineProps, ref, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';
const props = defineProps<{
    name: string
    score: number
    color: string
}>()


const labels = ref([props.name, ""])
const datasets = ref([{
    label: 'talenten',
    // sorted by score (highest to lowest)
    data: [props.score, 100 - props.score],
    backgroundColor: [props.color, "#D2D2DE"],
}])
const options = {
    datasets: {
        doughnut: {
            cutout: '66%',
            rotation: Math.PI / 2,
        }
    },
    plugins: {
        legend: {
            display: false,
        },
    },
}
const canvas = ref(null);
const chart = ref(null);
const renderChart = () => {
    if (chart.value) {
        chart.value.destroy();
    }
    var ctxGap = canvas.value.getContext('2d');
    chart.value = new Chart(ctxGap, {
        type: 'doughnut',
        options: options,
        data: {
            labels: labels.value,
            datasets: datasets.value
        }
    })
}


const mounted = onMounted(() => {
    Chart.register(...registerables);
    renderChart();
})
</script>
<template>
    <div>
        <div style="text-align: center;">{{ name }} </div>
        <div style="width:100px;height:100px;margin-left:5px">
            <canvas ref="canvas" width="200" height="200"></canvas>
        </div>
        <div style="text-align:center;margin-left: 5px;">{{ score }}</div>
    </div>
</template>