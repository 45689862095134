import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');
        
export function formatDate(dateString) {
    const date = dayjs(dateString);
        // Then specify how you want your dates to be formatted
    return date.format('dddd D MMMM, YYYY');
}

export default {
    formatDate
}
