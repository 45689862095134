<script setup lang="ts">

import { ref, computed } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm, usePage } from '@inertiajs/vue3';
import PrimaryButton from '@/Components/PrimaryButtonWhite.vue';
import PrimaryButtonBlue from '@/Components/PrimaryButton.vue';
import AssessmentLayout from '../../Layouts/AssessmentLayout.vue';
import SectionPage from '@/Components/SectionPage.vue';
import SectionIntroduction from '../../Components/SectionIntroduction.vue';
import SectionIntermission from '../../Components/SectionIntermission.vue';
import { seededShuffle } from '../../composables/seededShuffle';
import SectionHelp from '../../Components/SectionHelp.vue';

type Stage = 'intro' | 'questions' | 'finished';
type Answer = string | number | [string, string];
/* define props title, description, id, questions */
const props = defineProps<{
    assessment: object;
    sectionIndex: number;
    questionIndex: number
}>();


const { name, sections, id, status: { current_status } } = props.assessment;

const questions = computed(() => seededShuffle(sections[props.sectionIndex].questions, usePage().props.auth.user.id));

const page = ref(null);

const sectionStarted = ref(false);
const sectionStart = () => {
    sectionStarted.value = true;
}
const shortForm = computed(() => {
    return questions.value.length < 4;
})
if (shortForm.value) {
    sectionStarted.value = true;
}


const form = useForm({
    answers: [],
});

const submit = () => {
    if (page?.value.needsVerification) {
        return page?.value.verify();
    }
    if (!page?.value.open) {
        form.transform(data => ({
            ...data,
            answers: Object.fromEntries(page.value.answers),
        })).post(route('assessments.store', id), {
            onFinish: () => form.reset('answers'),
            onSuccess: () => {
                if (shortForm.value) {
                    finishSectionSubmit();
                } else {
                    router.reload({ only: ['questionIndex'] })
                }
            }
        });

    }
}


const sectionFinished = computed(() => {
    return props.questionIndex >= questions.value.length;
})

const finishSectionForm = useForm(
    {
        questionIndex: props.questionIndex,
    }
)

const finishSectionSubmit = () => {
    if (sectionFinished) {
        sectionStarted.value = false;
        finishSectionForm.post(route('assessments.finish', id), {
            onSuccess: () => {
                router.reload({ only: ['sectionIndex', 'questionIndex'] })
            }
        });
    }
}

const isIntro = computed(() => {
    return !sectionStarted.value && props.questionIndex < questions.value.length
})

const isIntermission = computed(() => {
    return current_status === 'in_progress' && sectionFinished.value
})

const isQuestions = computed(() => {
    return current_status === 'in_progress' && sectionStarted.value && !sectionFinished.value
})

</script>
<template>
    <AssessmentLayout :title="name" :totalPages="questions.length" :currentPage="props.questionIndex + 1"
                      :sections="sections" :section-index="props.sectionIndex" :isIntro="isIntro || isIntermission"
                      :shortForm="shortForm">
        <!-- show title, description and questions -->
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                {{ name }}
            </h2>
        </template>
        <template #help>
            <SectionHelp v-if="isQuestions"
                         class="ml-auto" :section="sections[props.sectionIndex]" />
        </template>
        <!-- show instruction and questions -->
        <SectionIntroduction v-if="isIntro" class="max-w-md mx-auto" :section="sections[props.sectionIndex]">
            <div class="flex justify-center">
                <PrimaryButton v-if="current_status === 'in_progress' && !sectionStarted && !sectionFinished"
                               @click="sectionStarted = true">
                    Start
                </PrimaryButton>
            </div>
        </SectionIntroduction>
        <SectionPage :key="props.questionIndex" ref='page'
                     v-if="isQuestions" :questions="questions"
                     :currentQuestionIndex="props.questionIndex" :sectionName="sections[props.sectionIndex].name">
        </SectionPage>
        <SectionIntermission v-show="isIntermission" class="max-w-md mx-auto" :currentSectionIndex="props.sectionIndex"
                             :sections="sections">
            <form class="w-full flex justify-center" @submit.prevent="finishSectionSubmit">
                <PrimaryButton v-if="props.sectionIndex < sections.length - 1"
                               :class="{ 'opacity-25': form.processing }" :disabled="page?.open || form.processing">
                    {{ 'Volgende' }}
                </PrimaryButton>
                <PrimaryButton v-show="!(props.sectionIndex < sections.length - 1)"
                               :class="{ 'opacity-25': form.processing }"
                               :disabled="page?.open || form.processing">
                    {{ 'Voltooien' }}
                </PrimaryButton>
            </form>

        </SectionIntermission>
        <template #previous>
            <p v-if="page?.isVerified" class="inline-block mr-2 text-sm self-center">U heeft de volgorde niet gewijzigd,
                klik op
                volgende om door te gaan.</p>
        </template>
        <template #next>
            <template v-if="current_status === 'in_progress' && !sectionFinished && sectionStarted">
                <form @submit.prevent="submit">
                    <PrimaryButtonBlue :processing="form.processing" :class="{ 'opacity-25': form.processing }"
                                       :disabled="page?.open || form.processing">
                        <!-- loading icon when form.processing -->
                        {{ shortForm ? 'Voltooien' : 'Volgende' }}
                    </PrimaryButtonBlue>
                </form>
            </template>

        </template>
    </AssessmentLayout>
</template>