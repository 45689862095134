<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import { useForm, usePage } from '@inertiajs/vue3';
import ActionMessage from '@/Components/ActionMessage.vue';
import ActionSection from '@/Components/ActionSection.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import DangerButton from '@/Components/DangerButton.vue';
import DialogModal from '@/Components/DialogModal.vue';
import FormSection from '@/Components/FormSection.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import SectionBorder from '@/Components/SectionBorder.vue';
import TextInput from '@/Components/TextInput.vue';

const props = defineProps({
    team: Object,
    availableRoles: Array,
    availableProfiles: Array,
    userPermissions: Object,
});

const addTeamMemberForm = useForm({
    email: '',
    role: props.availableRoles.some(({ key }) => key === 'member') ? 'member' : null,
});

const addTeamMember = () => {
    addTeamMemberForm.post(route('team-members.store', props.team), {
        errorBag: 'addTeamMember',
        preserveScroll: true,
        onSuccess: () => addTeamMemberForm.reset(),
    });
};

const cancelTeamInvitation = (invitation) => {
    router.delete(route('team-invitations.destroy', invitation), {
        preserveScroll: true,
    });
};

const remindTeamInvitation = (invitation) => {
    router.post(route('team-invitations.remind', invitation.id), {
        preserveScroll: true,
    });
};


const updateProfileForm = useForm({
    job_profile: [],
});

const currentlyManagingProfile = ref(false);
const managingProfileFor = ref(null);

const manageProfile = (teamMember) => {
    managingProfileFor.value = teamMember;
    updateProfileForm.job_profile = [...teamMember.profile_id];
    currentlyManagingProfile.value = true;
};

const updateProfile = () => {
    updateProfileForm.put(route('team-members.storeJobProfileUser', [props.team, managingProfileFor.value]), {
        preserveScroll: true,
        onSuccess: () => currentlyManagingProfile.value = false,
    });
};

const changeProfile = (profile) => {
    console.log(updateProfileForm.job_profile);
    updateProfileForm.job_profile = [profile.id]
    console.log(updateProfileForm.job_profile);
}


const updateRoleForm = useForm({
    role: null,
});
const currentlyManagingRole = ref(false);
const managingRoleFor = ref(null);

const manageRole = (teamMember) => {
    managingRoleFor.value = teamMember;
    updateRoleForm.role = teamMember.membership.role;
    currentlyManagingRole.value = true;
};

const updateRole = () => {
    updateRoleForm.put(route('team-members.update', [props.team, managingRoleFor.value]), {
        preserveScroll: true,
        onSuccess: () => currentlyManagingRole.value = false,
    });
};

const leaveTeamForm = useForm({
    leavingTeam: false
});
const confirmingLeavingTeam = ref(false);
const confirmLeavingTeam = () => {
    confirmingLeavingTeam.value = true;
};

const leaveTeam = () => {
    leaveTeamForm.delete(route('team-members.destroy', [props.team, usePage().props.auth.user]));
};

const removeTeamMemberForm = useForm({
    removeTeamMember: null
});
const teamMemberBeingRemoved = ref(null);
const confirmTeamMemberRemoval = (teamMember) => {
    teamMemberBeingRemoved.value = teamMember;
};

const removeTeamMember = () => {
    removeTeamMemberForm.delete(route('team-members.destroy', [props.team, teamMemberBeingRemoved.value]), {
        errorBag: 'removeTeamMember',
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => teamMemberBeingRemoved.value = null,
    });
};

const displayableRole = (role) => {
    return props.availableRoles.find(r => r.key === role).name;
};
</script>

<template>
    <div>
        <div v-if="userPermissions.canAddTeamMembers">
            <SectionBorder />

            <!-- Add Team Member -->
            <FormSection @submitted="addTeamMember">
                <template #title> Nodig iemand uit </template>

                <template #description>
                    Nodig een iemand uit voor dit team. Deze zal een email ontvangen met
                    instructies om zich aan te melden.
                </template>

                <template #form>
                    <div class="col-span-6">
                        <div class="max-w-xl text-sm text-gray-600">
                            Vul het emailadres in van de persoon die u wilt uitnodigen voor dit team.
                        </div>
                    </div>

                    <!-- Member Email -->
                    <div class="col-span-6 sm:col-span-4">
                        <InputLabel for="email" value="Email" />
                        <TextInput
                                   id="email"
                                   v-model="addTeamMemberForm.email"
                                   type="email"
                                   class="mt-1 block w-full" />
                        <InputError :message="addTeamMemberForm.errors.email" class="mt-2" />
                    </div>

                    <!-- Role -->
                    <div v-if="availableRoles.length > 0" class="col-span-6 lg:col-span-4">
                        <InputLabel for="roles" value="Role" />
                        <InputError :message="addTeamMemberForm.errors.role" class="mt-2" />

                        <div class="relative z-0 mt-1 border border-gray-200 rounded-lg cursor-pointer">
                            <button
                                    v-for="(role, i) in availableRoles"
                                    :key="role.key"
                                    type="button"
                                    class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                                    :class="{ 'border-t border-gray-200 rounded-t-none': i > 0, 'rounded-b-none': i != Object.keys(availableRoles).length - 1 }"
                                    @click="addTeamMemberForm.role = role.key">
                                <div
                                     :class="{ 'opacity-50': addTeamMemberForm.role && addTeamMemberForm.role != role.key }">
                                    <!-- Role Name -->
                                    <div class="flex items-center">
                                        <div class="text-sm text-gray-600"
                                             :class="{ 'font-semibold': addTeamMemberForm.role == role.key }">
                                            {{ role.name }}
                                        </div>

                                        <svg
                                             v-if="addTeamMemberForm.role == role.key"
                                             class="ml-2 h-5 w-5 text-green-400"
                                             fill="none"
                                             stroke-linecap="round"
                                             stroke-linejoin="round"
                                             stroke-width="2"
                                             stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                    </div>

                                    <!-- Role Description -->
                                    <div class="mt-2 text-xs text-gray-600 text-left">
                                        {{ role.description }}
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </template>

                <template #actions>
                    <ActionMessage :on="addTeamMemberForm.recentlySuccessful" class="mr-3">
                        Added.
                    </ActionMessage>

                    <PrimaryButton
                                   :class="{ 'opacity-25': addTeamMemberForm.processing }"
                                   :disabled="addTeamMemberForm.processing">
                        Nodig uit
                    </PrimaryButton>
                </template>
            </FormSection>
        </div>

        <div v-if="team.team_invitations.length > 0 && userPermissions.canAddTeamMembers">
            <SectionBorder />

            <!-- Team Member Invitations -->
            <ActionSection class="mt-10 sm:mt-0">
                <template #title> Team uitnodigingen </template>

                <template #description>
                    De mensen die uitgenodigd zijn voor dit team maar nog niet gereageerd hebben.
                </template>

                <!-- Pending Team Member Invitation List -->
                <template #content>
                    <div class="space-y-6">
                        <div v-for="invitation in team.team_invitations" :key="invitation.id"
                             class="flex items-center justify-between">
                            <div class="text-gray-600">
                                {{ invitation.email }}
                            </div>
                            <span class="text-gray-600" :title="`Uitnodiging gestuurd op ${new Date(invitation.created_at).toLocaleDateString('nl-NL')
                                }`">
                                {{ new Date(invitation.created_at).toLocaleDateString('nl-NL') }}
                            </span>

                            <div class="flex items-center cursor-pointer">
                                <span class="text-gray-600" :title="`Herinnering gestuurd op ${new Date(invitation.updated_at).toLocaleDateString('nl-NL')
                                    }`">
                                    {{ new Date(invitation.updated_at).toLocaleDateString('nl-NL') }}
                                </span>
                                <button
                                        v-if="userPermissions.canAddTeamMembers"
                                        class="cursor-pointer ml-6 text-sm text-gray-400 focus:outline-none underline"
                                        @click="remindTeamInvitation(invitation)">
                                    Herinner
                                </button>
                                <!-- Cancel Team Invitation -->
                                <button
                                        v-if="userPermissions.canRemoveTeamMembers"
                                        class="cursor-pointer ml-6 text-sm text-red focus:outline-none"
                                        @click="cancelTeamInvitation(invitation)">
                                    Annuleer
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </ActionSection>
        </div>

        <div v-if="team.users.length > 0">
            <SectionBorder />

            <!-- Manage Team Members -->
            <ActionSection class="mt-10 sm:mt-0">
                <template #title> Teamleden </template>

                <template #description>
                    Iedereen die onderdeel is van dit team.
                </template>

                <!-- Team Member List -->
                <template #content>
                    <div class="space-y-6">
                        <div v-for="user in team.users" :key="user.id" class="flex items-center justify-between">
                            <div class="flex items-center">
                                <img class="w-8 h-8 rounded-full" :src="user.profile_photo_url" :alt="user.name">
                                <div class="ml-4">
                                    <span class="block">{{ user.name }}</span>
                                    <!-- show and update function profile -->
                                    <button class="cursor-pointer text-sm text-gray-600"
                                            @click="manageProfile(user)">
                                        {{ user.profile_title ? user.profile_title : 'Functie' }}
                                        <font-awesome-icon icon="fa-solid fa-pen-to-square" class="ml-1 text-gray-500" />
                                    </button>
                                </div>
                            </div>

                            <div class="flex items-center">


                                <!-- Manage Team Member Role -->
                                <button
                                        v-if="userPermissions.canAddTeamMembers && availableRoles.length"
                                        class="ml-2 text-sm text-gray-400 underline"
                                        @click="manageRole(user)">
                                    {{ displayableRole(user.membership.role) }}
                                </button>

                                <div v-else-if="availableRoles.length" class="ml-2 text-sm text-gray-400">
                                    {{ displayableRole(user.membership.role) }}
                                </div>

                                <!-- Leave Team -->
                                <button
                                        v-if="$page.props.auth.user.id === user.id"
                                        class="cursor-pointer ml-6 text-sm text-red"
                                        @click="confirmLeavingTeam">
                                    Verlaat
                                </button>

                                <!-- Remove Team Member -->
                                <button
                                        v-else-if="userPermissions.canRemoveTeamMembers"
                                        class="cursor-pointer ml-6 text-sm text-red"
                                        @click="confirmTeamMemberRemoval(user)">
                                    Verwijder
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
            </ActionSection>
        </div>

        <!-- Job profile Management Modal -->
        <DialogModal :show="currentlyManagingProfile" @close="currentlyManagingProfile = false">
            <template #title>
                Pas functieprofiel aan
            </template>

            <template #content>
                <div v-if="managingProfileFor">
                    <button
                            v-for="(profile, i) in availableProfiles"
                            :key="profile.title"
                            type="button"
                            class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                            :class="{ 'border-t border-gray-200 rounded-t-none': i > 0, 'rounded-b-none': i !== Object.keys(availableProfiles).length - 1 }"
                            @click="changeProfile(profile)">

                        <div
                             :class="{ 'opacity-50': updateProfileForm.job_profile && updateProfileForm.job_profile.includes(profile.id) }">
                            <!-- Profile Name -->
                            <div class="flex items-center">
                                <div class="text-sm text-gray-600"
                                     :class="{ 'font-semibold': updateProfileForm.job_profile.includes(profile.id) }">
                                    {{ profile.title }}
                                </div>

                                <svg
                                     v-if="updateProfileForm.job_profile.includes(profile.id)"
                                     class="ml-2 h-5 w-5 text-green-400"
                                     fill="none"
                                     stroke-linecap="round"
                                     stroke-linejoin="round"
                                     stroke-width="2"
                                     stroke="currentColor"
                                     viewBox="0 0 24 24">
                                    <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                        </div>
                    </button>
                </div>
            </template>
            <template #footer>
                <SecondaryButton @click="currentlyManagingProfile = false">
                    Annuleer
                </SecondaryButton>

                <PrimaryButton
                               class="ml-3"
                               :class="{ 'opacity-25': updateProfileForm.processing }"
                               :disabled="updateProfileForm.processing"
                               @click="updateProfile">
                    Opslaan
                </PrimaryButton>
            </template>
        </DialogModal>

        <!-- Role Management Modal -->
        <DialogModal :show="currentlyManagingRole" @close="currentlyManagingRole = false">
            <template #title>
                Pas rol aan
            </template>

            <template #content>
                <div v-if="managingRoleFor">
                    <div class="relative z-0 mt-1 border border-gray-200 rounded-lg cursor-pointer">
                        <button
                                v-for="(role, i) in availableRoles"
                                :key="role.key"
                                type="button"
                                class="relative px-4 py-3 inline-flex w-full rounded-lg focus:z-10 focus:outline-none focus:border-blue-300 focus:ring focus:ring-blue-200"
                                :class="{ 'border-t border-gray-200 rounded-t-none': i > 0, 'rounded-b-none': i !== Object.keys(availableRoles).length - 1 }"
                                @click="updateRoleForm.role = role.key">
                            <div :class="{ 'opacity-50': updateRoleForm.role && updateRoleForm.role !== role.key }">
                                <!-- Role Name -->
                                <div class="flex items-center">
                                    <div class="text-sm text-gray-600"
                                         :class="{ 'font-semibold': updateRoleForm.role === role.key }">
                                        {{ role.name }}
                                    </div>

                                    <svg
                                         v-if="updateRoleForm.role === role.key"
                                         class="ml-2 h-5 w-5 text-green-400"
                                         fill="none"
                                         stroke-linecap="round"
                                         stroke-linejoin="round"
                                         stroke-width="2"
                                         stroke="currentColor"
                                         viewBox="0 0 24 24">
                                        <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>

                                <!-- Role Description -->
                                <div class="mt-2 text-xs text-gray-600">
                                    {{ role.description }}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </template>

            <template #footer>
                <SecondaryButton @click="currentlyManagingRole = false">
                    Annuleer
                </SecondaryButton>

                <PrimaryButton
                               class="ml-3"
                               :class="{ 'opacity-25': updateRoleForm.processing }"
                               :disabled="updateRoleForm.processing"
                               @click="updateRole">
                    Opslaan
                </PrimaryButton>
            </template>
        </DialogModal>

        <!-- Leave Team Confirmation Modal -->
        <ConfirmationModal
                           :show="confirmingLeavingTeam"
                           @close="confirmingLeavingTeam = false">
            <template #title> Verlaat Team </template>

            <template #content> Weet je zeker dat je het team wilt verlaten? </template>

            <template #footer>
                <SecondaryButton @click="confirmingLeavingTeam = false">
                    Annuleer
                </SecondaryButton>

                <DangerButton
                              class="ml-3"
                              :class="{ 'opacity-25': leaveTeamForm.processing }"
                              :disabled="leaveTeamForm.processing"
                              @click="leaveTeam">
                    Verlaat
                </DangerButton>
            </template>
        </ConfirmationModal>

        <!-- Remove Team Member Confirmation Modal -->
        <ConfirmationModal
                           :show="teamMemberBeingRemoved"
                           @close="teamMemberBeingRemoved = null">
            <template #title> Verwijder Teamlid </template>

            <template #content>
                Are you sure you would like to remove this person from the team?
            </template>

            <template #footer>
                <SecondaryButton @click="teamMemberBeingRemoved = null">
                    Annuleer
                </SecondaryButton>

                <DangerButton
                              class="ml-3"
                              :class="{ 'opacity-25': removeTeamMemberForm.processing }"
                              :disabled="removeTeamMemberForm.processing"
                              @click="removeTeamMember">
                    Verwijder
                </DangerButton>
            </template>
        </ConfirmationModal>
    </div>
</template>
