<script setup lang="ts">
import { ref, computed, defineEmits, defineExpose } from 'vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
const props = defineProps<{ list: any[], searchFunction: Function, searchLabel: String, searchPlaceholder: String }>();

const showAll = ref(false);

const search = ref('');


const searchList = computed(() => {
    return props.list.filter((item) => {
        return props.searchFunction(item, search.value)
    });
});

defineExpose({
    searchList
})

</script>
<template>
    <div>
        <InputLabel class="w-full p-3 text-base">Zoeken
            <TextInput v-model="search" class="w-full border mb-3 p-2" placeholder="Zoek op naam" />
        </InputLabel>
        <div :class="{ cards: !showAll }"
             class="max-w-7xl full flex gap-12 mx-auto py-10 sm:px-6 lg:px-8 shrink-0 grow-0 flex-wrap">
            <slot />

        </div>
        <div v-if="list.length === 0" class="w-full text-center text-gray-600">
            <span v-if="!$slots.emptyList">
                Er is niemand om te beoordelen</span>
            <template v-else>
                <slot name="emptyList" />
            </template>
        </div>
        <div v-if="list.length > 0 && searchList.length === 0" class="w-full text-center text-gray-600">
            <span v-if="!$slots.zeroResults">Niemand gevonden</span>
            <template v-else>
                <slot name="zeroResults" />
            </template>
        </div>
        <button v-if="searchList.length > 6" @click="showAll = true" :class="{ 'invisible': showAll }"
                class="w-full text-center text-gray-600">
            <font-awesome-icon class="w-5 h-5 mx-auto" icon="fa-solid fa-chevron-down" />
            <span v-if="!$slots.showAllButton">Toon alles</span>
            <template v-else>
                <slot name="showAllButton" />
            </template>
        </button>
    </div>
</template>
<style lang="css">
.cards>* {
    display: none;
}

.cards>*:nth-child(-n+6) {
    display: flex;
}
</style>
